
import { computed, defineComponent, PropType } from 'vue';
import DropdownAutocompleteBase from "@/components/DropdownAutocompleteBase.vue";

export default defineComponent({
  name: 'dropdown-autocomplete-multi-select',
  components: { DropdownAutocompleteBase },
  extends: DropdownAutocompleteBase,
  emits: ['update:modelValue', 'onSelect'],
  props: {
    modelValue: {
      type: Array as PropType<Array<unknown>>,
    },
    isNewUi: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const highlightedItems = computed(() =>
        props.modelValue);

    function handleSelect(value: any) {

      if (value) {
        const modelValueCopy = props.modelValue ? [...props.modelValue] : [];
        const index = modelValueCopy.findIndex((d) => d.includes(value));
        if (index > -1) {
          modelValueCopy.splice(index, 1);
        } else {
          modelValueCopy.unshift(value);
        }
        context.emit('update:modelValue', modelValueCopy);
        context.emit('onSelect', modelValueCopy);
      }
    }

    return {
      handleSelect,
      highlightedItems,
    };
  },
});

