import RouterGuards from "@/router/router-guards";
import ComponentBom from "@/domain/proposal/ComponentBom";
import SearchController from "@/views/search/SearchController.vue";

const myRouterGuards = new RouterGuards();

// should have type Array<RouteConfig>, for whatever reason its giving some stupid error message
export const routes: any = [
    // { path: '*', redirect: '/' },
    { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/'},

    {
        path: '/login',
        name: 'LoginScreen',
        // lazy-loading when the route is visited.
        component: () => import('@/views/auth/AuthScreen.vue'),
        // beforeEnter: myRouterGuards.ifNotAuthenticated
    },


    //{ path: '/login/callback', name: 'login-callback', component: LoginCallback },
    {
        path: '/',
        name: 'AdvancedSearch',
        component: SearchController,
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/proposal/:proposalNumber',
        name: 'Proposal',
        component: () => import('@/views/proposal/ProposalScreen.vue'),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                redirect: {name: 'ProposalHeader'}
            },
            {
                path: 'proposal-head',
                name: 'ProposalHeader',
                component: () => import('@/views/proposal/base/forms/ProposalHead.vue')
            },
            {
                path: 'proposal-info',
                name: 'ProposalScheduling',
                component: () => import('@/views/proposal/base/forms/ProposalInfo.vue')
            },
            {
                path: 'text-maintenance',
                name: 'ProposalTextMaintenance',
                component: () => import('@/views/proposal/base/forms/TextMaintenance.vue')
            },
            {
                path: 'sample-data',
                name: 'ProposalSample',
                component: () => import('@/views/proposal/base/forms/SampleData.vue')
            },
            {
                path: 'product-bom-table',
                name: 'ProposalCosting',
                component: () => import('@/views/proposal/product-bom/ProductBomTable.vue')
            },
            {
                path: '/proposal/:proposalNumber/component-bom-screen/:componentNumber/:componentBomID',
                name: 'ComponentBom',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomScreen.vue'),
                beforeEnter: myRouterGuards.ifAuthenticated,
            },
            {
                path: '/proposal/:proposalNumber/component-bom-items-table/:componentNumber/:componentBomID',
                name: 'CmpBomItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomItemsTable.vue'),
                beforeEnter: myRouterGuards.ifAuthenticated,
            },
            {
                path: '/proposal/:proposalNumber/component-bom-route-items-table/:componentNumber/:componentBomID',
                name: 'CmpRouteItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomRouteItemsTable.vue')
            },
            {
                path: '/proposal/:proposalNumber/component-bom-tooling-items-table/:componentNumber/:componentBomID',
                name: 'CmpToolingItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomToolingItemsTable.vue')
            },
            {
                path: 'purchased-item-table',
                name: 'PurchasedItem',
                component: () => import('@/views/proposal/product-bom/purchased-item/PurchasedItemTable.vue')
            },
            {
                path: 'route-item-table',
                name: 'AssemblyRoute',
                component: () => import('@/views/proposal/product-bom/prod-route-item/RouteItemTable.vue')
            },
            {
                path: 'amort-item-table',
                name: 'AmortizedTable',
                component: () => import('@/views/proposal/product-bom/amort-item/AmortItemTable.vue')
            },
            {
                path: 'sub-assemblies',
                name: 'SubAssemblies',
                component: () => import('@/views/proposal/base/forms/SubAssembly/SubAssembly.vue')
            },
            {
                path: 'component-totals',
                name: 'ProposalComponentTotals',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentTotals.vue')
            },
            {
                path: 'cost-history',
                name: 'ProposalCost',
                component: () => import('@/views/proposal/base/forms/CostHistory.vue')
            },
            {
                path: 'reports',
                name: 'ProposalReports',
                component: () => import('@/views/proposal/reports/ReportMenu.vue')
            }
        ]
    },
    {
        path: '/proposal-new/:proposalNumber',
        name: 'ProposalNew',
        component: () => import('@/views/proposal/ProposalScreen.vue'),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                redirect: {name: 'ProposalHeader'}
            },
            {
                path: 'proposal-head',
                name: 'NewProposalHeader',
                component: () => import('@/views/proposal/base/forms/ProposalHead.vue')
            },
            {
                path: 'proposal-info',
                name: 'NewProposalScheduling',
                component: () => import('@/views/proposal/base/forms/ProposalInfo.vue')
            },
            {
                path: 'text-maintenance',
                name: 'NewProposalTextMaintenance',
                component: () => import('@/views/proposal/base/forms/TextMaintenance.vue')
            },
            {
                path: 'sample-data',
                name: 'NewProposalSample',
                component: () => import('@/views/proposal/base/forms/SampleData.vue')
            },
            {
                path: 'product-bom-table',
                name: 'NewProposalCosting',
                component: () => import('@/views/proposal/product-bom/ProductBomTable.vue')
            },
            {
                path: '/proposal-new/:proposalNumber/component-bom-screen/:componentNumber/:componentBomID',
                name: 'NewComponentBom',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomScreen.vue'),
                beforeEnter: myRouterGuards.ifAuthenticated,
            },
            {
                path: '/proposal-new/:proposalNumber/component-bom-items-table/:componentNumber/:componentBomID',
                name: 'NewCmpBomItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomItemsTable.vue'),
                beforeEnter: myRouterGuards.ifAuthenticated,
            },
            {
                path: '/proposal-new/:proposalNumber/component-bom-route-items-table/:componentNumber/:componentBomID',
                name: 'NewCmpRouteItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomRouteItemsTable.vue')
            },
            {
                path: '/proposal-new/:proposalNumber/component-bom-tooling-items-table/:componentNumber/:componentBomID',
                name: 'NewCmpToolingItems',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentBomToolingItemsTable.vue')
            },
            {
                path: 'purchased-item-table',
                name: 'NewPurchasedItem',
                component: () => import('@/views/proposal/product-bom/purchased-item/PurchasedItemTable.vue')
            },
            {
                path: 'route-item-table',
                name: 'NewAssemblyRoute',
                component: () => import('@/views/proposal/product-bom/prod-route-item/RouteItemTable.vue')
            },
            {
                path: 'amort-item-table',
                name: 'NewAmortizedTable',
                component: () => import('@/views/proposal/product-bom/amort-item/AmortItemTable.vue')
            },
            {
                path: 'sub-assemblies',
                name: 'NewSubAssemblies',
                component: () => import('@/views/proposal/base/forms/SubAssembly/SubAssembly.vue')
            },
            {
                path: 'component-totals',
                name: 'NewProposalComponentTotals',
                component: () => import('@/views/proposal/product-bom/component-bom/ComponentTotals.vue')
            },
            {
                path: 'cost-history',
                name: 'NewProposalCost',
                component: () => import('@/views/proposal/base/forms/CostHistory.vue')
            },
            {
                path: 'reports',
                name: 'NewProposalReports',
                component: () => import('@/views/proposal/reports/ReportMenu.vue')
            }
        ]
    },
    {
        path: '/proposal/:proposalNumber/component-bom-table/:componentNumber/:componentBomID',
        name: 'ComponentBomTable',
        component: () => import("@/views/proposal/product-bom/component-bom/ComponentBomScreen.vue"),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/proposal/:proposalNumber/route-item/:routeItemID',
        name: 'RouteItem',
        component: () => import("@/views/proposal/product-bom/prod-route-item/ProdRouteItemScreen.vue"),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/proposal/:proposalNumber/amort-item/:amortItemID',
        name: 'AmortItem',
        component: () => import("@/views/proposal/product-bom/amort-item/AmortItemScreen.vue"),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/copyProposal/:proposalNumber/:productNumber',
        name: 'LegacyCopy',
        component: () => import('@/views/proposal/base/forms/CopyProposalForm.vue'),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/copyTool/:initProposalId?',
        name: 'CopyProposal',
        component: () => import('@/views/copy/CopyProposalController.vue'),
        props: true,
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workflowMaintenance',
        name: 'WorkflowScreen',
        component: () => import('@/views/admin/WorkflowScreen.vue'),
        beforeEnter: myRouterGuards.ifAuthorized,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/workflowGroupActionDetails/:workflowGroupActionID',
        name: 'WorkflowGroupActionDetails',
        component: () => import('@/views/admin/workflow/WorkflowGroupActionDetails.vue'),
        beforeEnter: myRouterGuards.ifAuthorized
    },
    {
        path: '/workflowConditions/:workflowGARuleId',
        name: 'WorkflowConditions',
        component: () => import('@/views/admin/workflow/WorkflowConditions.vue'),
        beforeEnter: myRouterGuards.ifAuthorized
    },
    {
        path: '/workflowGroups',
        name: 'WorkflowGroupDetails',
        component: () => import('@/views/admin/workflow/WorkflowGroups.vue'),
        beforeEnter: myRouterGuards.ifAuthorized
    },
    {
        path: '/admin/maintenance',
        name: 'MaintenanceScreen',
        component: () => import('@/views/admin/MaintenanceScreen.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'EditMaintenanceItemsScreen',
                path: 'edit-tables-screen',
                meta: {parent: 'maintenance'},
                component: () => import('@/views/admin/maintenance/MaintenanceTables.vue')
            },
        ]
    },
    {
        path: '/admin/designerskills',
        name: 'DesignerSkills',
        component: () => import('@/views/admin/DesignerSkillTable.vue'),
        beforeEnter: myRouterGuards.ifDesignLead,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/admin/customerProducts',
        name: 'CustomerProducts',
        component: () => import('@/views/admin/CustomerProductEditor.vue'),
        beforeEnter: myRouterGuards.ifDesignLead,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/designQueue',
        name: 'DesignQueue',
        component: () => import('@/views/DesignQueue/DesignQueue.vue'),
        beforeEnter: myRouterGuards.ifDesignLead,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/designerQueue',
        name: 'DesignerQueue',
        component: () => import('@/views/DesignQueue/DesignerQueue.vue'),
        beforeEnter: myRouterGuards.ifDesigner,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/userLocations',
        name: 'UserLocations',
        component: () => import('@/views/admin/UserLocationManager.vue'),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/productionOrders',
        name: 'ProductionOrders',
        component: () => import('@/views/production/ProductionOrders.vue'),
        beforeEnter: myRouterGuards.ifAdmin,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/production',
        name: 'Production',
        component: () => import('@/views/production/ProductionCounts.vue'),
        beforeEnter: myRouterGuards.ifAdmin,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/proposal-main-area',
        name: 'Proposal Main Area',
        component: () => import('@/views/proposal/newUI/ProposalMainArea.vue'),
        beforeEnter: myRouterGuards.ifAuthenticated,
        meta: {
            requiresAuth: true,
        },
    }
]
