
import { defineComponent, PropType } from 'vue';
type TeamMemberType = {
    role: string;
    name: string;
    imageURL: string;
}

export default defineComponent({
    name: 'team-section-item',
    props: {
        teamMember: {
            type: Object as PropType<TeamMemberType>,
            required: true
        },
    },
    data() {
        return {
        };
    },
    setup(props, context) {

        return {
        }
    }
});
