import AuthApiService from "@/service/api/AuthApiService";
import ApiConfig from "@/service/api/api-config";
import router from "@/router";
import {TOKEN_NAME} from "@/config/properties";
import MaintenanceService from "@/service/MaintenanceService";
import BaseService from "@/service/BaseService";
import {GeneralStatusResponse} from "@/service/api/types/ApiResponseTypes";
import NotificationService from "@/service/NotificationService";
import SearchService from "@/service/SearchService";
import UserGroupApiService from "@/service/api/UserGroupApiService";
import UserService from "@/service/UserService";
import Group from "@/domain/profile/Group";

export default class StartupService extends BaseService {
    private authApiService: AuthApiService;
    private searchService: SearchService;
    private maintenanceService: MaintenanceService;
    private notificationService: NotificationService;
    private userGroupApiService: UserGroupApiService;
    private userService: UserService;
    private apiConfig: ApiConfig;

    constructor() {
        super()
        this.apiConfig = ApiConfig.getInstance(); // needed to initiate interceptor
        this.authApiService = new AuthApiService();
        this.maintenanceService = new MaintenanceService();
        this.notificationService = new NotificationService();
        this.searchService = new SearchService();
        this.userGroupApiService = new UserGroupApiService();
        this.userService = new UserService();
    }


    public async startApp(fullUrl: string) {
        //ApiConfig.getInstance();

        // If token exists in url, grab it and set it.
        // If it doesnt, look in local storage for last used token and set it
        let token;
        const {url, tokenFromUrl} = this.extractTokenFromUrl(fullUrl)
        if (tokenFromUrl) {
            token = tokenFromUrl
        } else {
            token = localStorage.getItem(TOKEN_NAME);
        }
        if (token) {
            this.apiConfig.setToken(token);
            await this.initStartupData(url);
        } else {
            this.rootStore.loadingStore.hideLoadingProgressScreen()
        }
    }

    public async initStartupData(url?: string): Promise<GeneralStatusResponse> {

        // Store info about all groups and users in system.
        const userGroupStore = this.rootStore.userGroupStore;
        await this.userGroupApiService.getAllGroups()
            .then((value) => {
                (value.success && value.items)? userGroupStore.groups.value = value.items : console.log("Failed to load user groups")});
        await this.userGroupApiService.getAllUsers()
            .then((value) => {
                (value.success && value.items)? userGroupStore.users.value = value.items : console.log("Failed to load users")});

        const response = await this.authApiService.getProfile(); // Fetch authentication from userservice
        if (response.success) {
            const user = userGroupStore.users.value.find(user => {return user.username.toLowerCase() === response.profile.username.toLowerCase();})
            if(user) {
                this.rootStore.profileStore.userID = user.userID; // Set Momentum UserID separately as it is not provided in the UserService profile data.
                // If authenticated by user service, fetch user groups from momentum. If not in "All Users" group, return failed status
                const groupsResponse = await this.authApiService.getGroupsByUserID(user.userID);
                if (groupsResponse.success && groupsResponse.items && groupsResponse.items.filter(g => {
                    return g.userGroupName.toLowerCase() == 'all users';
                }).length > 0) {
                    await this.userGroupApiService.getSpoofAllowedStatus()
                        .then((value) => { (value.success && value.item)? this.rootStore.profileStore.setSpoofingAllowed(value.item) : console.log('Failed to set spoof allowed status') });

                    const spoofingActiveStr = localStorage.getItem("groupSpoofingActive");
                    const spoofingActive = spoofingActiveStr ? JSON.parse(spoofingActiveStr) : false;

                    // Only load spoofed user groups if user's real group's contain spoofing permission and spoofing is toggled on.
                    if (this.rootStore.profileStore.allowsSpoofing && spoofingActive) {
                        const spoofedGroupIDsStr = localStorage.getItem("spoofedUserGroups");
                        if (!spoofedGroupIDsStr) {
                            console.log("Could not load groups to spoof");
                            response.profile.groups = groupsResponse.items; // Failed to load spoofed groups. Load normal groups.
                        } else {
                            const groupArr: Array<Group> = [];
                            const groupIDs = JSON.parse(spoofedGroupIDsStr) as Array<number>;
                            const groupSpoofResponse = await this.userGroupApiService.getSpoofedGroups(groupIDs);
                            if (groupSpoofResponse.success) {
                                if (groupSpoofResponse.items) {
                                    groupSpoofResponse.items.forEach((groupLiteral) => {groupArr.push(new Group(groupLiteral))}); // Create objects from JSON literals
                                }
                            } else {
                                console.log("Could not get groups to spoof");
                            }
                            response.profile.groups = groupArr.length > 0 ? groupArr : groupsResponse.items;
                        }
                    } else {
                        // If user does not have spoofing permissions or has not activated spoofing, load groups normally.
                        response.profile.groups = groupsResponse.items;
                    }
                    this.rootStore.profileStore.initProfile(response.profile);
                    console.log("Profile initiated");
                    const availableSearches = await this.searchService.getAllSearchesWithPermission();
                    if (availableSearches.success) {
                        this.rootStore.profileStore.setSearches(availableSearches.ownSearches, availableSearches.sharedSearches);
                    }
                }
            } else {
                    return {
                        success: false,
                        errMsg: "User is not authorized to access Momentum. If you need access, please send a request to orbis.momentum@orbiscorporation.com"
                    };
            }

                this.rootStore.loadingStore.showLoadingProgressScreen();
                const allContainerModelsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllContainerModels();
                    resolve(response);
                })
                const allMaterialResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllMaterials();
                    resolve(response);
                });
                const airCellRulesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllAirCellRules();
                    resolve(response);
                });
                const amortCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllAmortCodes();
                    resolve(response);
                });
                const customerPartTypeResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllCustomerPartTypes();
                    resolve(response);
                });
                const designAttentionResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllDesignAttentions();
                    resolve(response);
                });
                // Deprecated. Being replaced by ProposalStatuses.
                const qaStatusesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getQaStatuses();
                    resolve(response);
                })
                const proposalStatusesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getProposalStatuses();
                    resolve(response);
                })
                const productNumbersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getProductNumbers();
                    resolve(response);
                })
                const customerPartDescsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getCustomerPartDescs();
                    resolve(response);
                })
                const heatSealingRulesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllHeatSealingRules();
                    resolve(response);
                });
                const hierarchiesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllHierarchies();
                    resolve(response);
                });
                const holdCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllHoldCodes();
                    resolve(response);
                });
                const materialHoldCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllMaterialHoldCodes();
                    resolve(response);
                })
                const materialBlanksResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllMaterialBlanks();
                    resolve(response);
                });
                const allMaterialUseCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getMaterialUseCodes();
                    resolve(response);
                })
                const mfgRatesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllMfgRates();
                    resolve(response);
                })
                const mfgFactorResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllMfgFactors();
                    resolve(response);
                })
                const allPlantsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllPlants();
                    resolve(response)
                })
                const allProposalSearchFiltersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllProposalSearchFilters();
                    resolve(response)
                })
                const allProjectDefaults = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllProjectDefaults();
                    resolve(response)
                })
                const allProjectTypeCodes = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllProjectTypeCodes();
                    resolve(response);
                })
                const reasonsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllReasons();
                    resolve(response);
                });
                const recycleCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllRecycleCodes();
                    resolve(response);
                });
                const wcOpResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWCOperations();
                    resolve(response);
                });
                const workCentersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWorkCenters();
                    resolve(response);
                });
                const wcMaintResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWCMaint();
                    resolve(response);
                });
                const toolingRateResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllToolingRates();
                    resolve(response);
                });
                const routingPathResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllRoutingPaths();
                    resolve(response);
                });
                const currenciesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllCurrencies();
                    resolve(response);
                });
                const customerNamesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getCustomerNames();
                    resolve(response);
                });
                const sampleAddressesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleAddresses();
                    resolve(response);
                });
                const sampleCitiesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleCities();
                    resolve(response);
                });
                const sampleStatesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleStates();
                    resolve(response);
                });
                const sampleCountriesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleCountries();
                    resolve(response);
                });
                const sampleZipsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleZips();
                    resolve(response);
                });
                const sampleAttentionTosResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleAttentionTos();
                    resolve(response);
                });
                const samplePhonesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSamplePhones();
                    resolve(response);
                });
                const projectManagersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getProjectManagers();
                    resolve(response);
                });
                const ramSamResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getRamSam();
                    resolve(response);
                });
                const crmCsmResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getCrmCsm();
                    resolve(response);
                });
                const activeUsersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getActiveUsers();
                    resolve(response);
                });
                const approvedByResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getApprovedBy();
                    resolve(response);
                });
                const designersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getDesigners();
                    resolve(response);
                });
                const estimatorsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getEstimators();
                    resolve(response);
                });
                const sampleLocationsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleLocations();
                    resolve(response);
                });
                const sampleCodesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleCodes();
                    resolve(response);
                });
                const sampleMakersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleMakers();
                    resolve(response);
                })
                const dunnageAttachmentResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getDunnageAttachments();
                    resolve(response);
                })
                const commentTypeResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getCommentTypes();
                    resolve(response);
                })
                const taggableUsersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.notificationService.getTaggableUsers();
                    resolve(response);
                })

                const workflowGroupsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWorkflowGroups();
                    resolve(response);
                })
                const workflowGroupActionResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWorkflowGroupActions();
                    resolve(response);
                })
                const workflowGARulesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getWorkflowGARules();
                    resolve(response);
                })
                const workflowActionStatusesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWorkflowActionStatuses();
                    resolve(response);
                })
                const workflowActionsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWorkflowActions();
                    resolve(response);
                })
                const workflowActivityTypesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllWfActivityTypes();
                    resolve(response);
                })
                const workflowFieldDTOsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getWorkflowFieldData();
                    resolve(response);
                })
                const sampleStatusesResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getSampleStatuses();
                    resolve(response);
                })
                // User - Orderable Advanced Table Column setups
                const userTableColumnSetupsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.userService.getUserOrderableTableSetups();
                    resolve(response);
                })

                const priceHoldsResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getPriceHoldList();
                    resolve(response);
                })

                const sampleCarriersResult = new Promise<boolean>(async (resolve) => {
                    const response = await this.maintenanceService.getAllSampleCarriers();
                    resolve(response);
                })

            const skillGroupsResult = new Promise<boolean>(async (resolve) => {
                const response = await this.maintenanceService.getAllSkillGroups();
                resolve(response)
            })

            const momentumProductsResult = new Promise<boolean>(async (resolve) => {
                const response = await this.maintenanceService.getAllMomentumProducts();
                resolve(response);
            })

            const momentumCustomersResult = new Promise<boolean>( async (resolve) => {
                const response = await this.maintenanceService.getAllMomentumCustomers();
                resolve(response);
            })

            const pricelistHeadersResult = new Promise<boolean>(async (resolve) => {
                const response = await this.maintenanceService.getPriceListHeaders();
                resolve(response);
            })

            const textTemplatesHeadersResult = new Promise<boolean>( async (resolve) => {
                const response = await this.maintenanceService.getTextTemplatesHeaders();
                resolve(response);
            })

            const textTemplatesItemsResult = new Promise<boolean>( async (resolve) => {
                const response = await this.maintenanceService.getTextTemplatesItems();
                resolve(response);
            })

            const effortCodeOptionsResult = new Promise<boolean>(async (resolve) => {
                const response = await this.maintenanceService.getEffortCodeOptions();
                resolve(response);
            })

            const fobDescriptionsResult = new Promise<boolean>(async (resolve) => {
                const response = await this.maintenanceService.getFOBDescriptions();
                resolve(response);
            })

            this.maintenanceService.createHierarchyTypes();

                function startupProgress(proms: any, progressCb: any) {
                    let d = 0;
                    progressCb(0);
                    for (const p of proms) {
                        p.then(() => {
                            d++;
                            progressCb((d * 100) / proms.length);
                        });
                    }
                    return Promise.all(proms);
                }

                startupProgress([
                        reasonsResult, wcOpResult, workCentersResult, amortCodesResult, allMaterialResult, allMaterialUseCodesResult,
                        allContainerModelsResult, allPlantsResult, airCellRulesResult, customerPartTypeResult, designAttentionResult,
                        qaStatusesResult, proposalStatusesResult, productNumbersResult, customerPartDescsResult, heatSealingRulesResult, hierarchiesResult,
                        holdCodesResult, materialBlanksResult, mfgRatesResult, mfgFactorResult,
                        allProjectDefaults, allProjectTypeCodes, recycleCodesResult, wcMaintResult, toolingRateResult,
                        allProposalSearchFiltersResult, routingPathResult, currenciesResult, customerNamesResult, sampleAddressesResult,
                        sampleCitiesResult, sampleStatesResult, sampleCountriesResult, sampleZipsResult, sampleAttentionTosResult, samplePhonesResult,
                        projectManagersResult, ramSamResult, crmCsmResult, approvedByResult, designersResult, estimatorsResult, sampleLocationsResult,
                        sampleCodesResult, dunnageAttachmentResult, taggableUsersResult, commentTypeResult, workflowGroupsResult, workflowGroupActionResult,
                        workflowActionStatusesResult, workflowFieldDTOsResult, workflowActionsResult, workflowGARulesResult, workflowActivityTypesResult, sampleStatusesResult,
                        priceHoldsResult, userTableColumnSetupsResult, sampleCarriersResult, activeUsersResult, pricelistHeadersResult,
                        textTemplatesHeadersResult, textTemplatesItemsResult, effortCodeOptionsResult, fobDescriptionsResult

                    ],
                    (p: number) => {
                        this.rootStore.loadingStore.progressPercent = p
                        if (p === 100) {
                            this.rootStore.loadingStore.hideLoadingProgressScreen()
                        }
                    }).then((values) => {
                        if (values) {
                            if (url && this.getDeepLink(url).length > 0) {
                                router.push(this.getDeepLink(url))
                            } else {
                                router.push({name: 'AdvancedSearch'})
                            }
                        } else {
                            // not sure if i want something to happen here
                            //router.push({name: 'login'});
                        }
                    });
        }
        return {success: true}
    }

    private getDeepLink(url: string): string {
        // router strips out everything that doesnt have #, must be included
        if (url.includes('#')) {
            const deepLink = url.split('#')[1]
            if (deepLink !== '/login') {
                return url.split('#')[1]
                /*
        const baseUrl = window.location.origin;
        const currentPath = router.currentRoute.value.fullPath
        console.log(currentPath);
        if (url.includes(baseUrl + "/" + BASE_URL)) {
            const deepLink = url.split(baseUrl + "/" + BASE_URL)[1]
            if (deepLink !== '/login/callback') {
                return deepLink;

                 */
            } else {
                return ""
            }
        }
        return "";
    }

    private extractTokenFromUrl(fullUrl: string): { url: string, tokenFromUrl: string | null } {
        if (fullUrl.includes('?token=')) {
            const token = fullUrl.split('?token=')[1]
            const url = fullUrl.split('?token=')[0]
            return {url, tokenFromUrl: token}
        }
        return {url: fullUrl, tokenFromUrl: null}

    }

}
