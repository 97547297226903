import {
    GetGeneralListSuccessResult, GetGeneralSuccessResult,
    GetSingleItemResult, MaintenanceClassName,
} from "@/service/api/types/ApiResponseTypes";
import {BASE_API_URL} from "@/config/env";
import WorkCenter from "@/domain/maintenance/WorkCenter";
import ReasonCode from "@/domain/maintenance/ReasonCode";
import WCOperation from "@/domain/maintenance/WCOperation";
import AmortCode from "@/domain/maintenance/AmortCode";
import AllMaterial from "@/domain/maintenance/material/AllMaterial";
import MaterialUseCode from "@/domain/maintenance/material/MaterialUseCode";
import ProjectTypeCode from "@/domain/maintenance/ProjectTypeCode";
import ContainerModel from "@/domain/maintenance/ContainerModel";
import Plant from "@/domain/maintenance/Plant";
import AirCellRules from "@/domain/maintenance/AirCellRules";
import CustomerPartType from "@/domain/maintenance/CustomerPartType";
import DesignAttention from "@/domain/maintenance/DesignAttention";
import HeatSealingRules from "@/domain/maintenance/HeatSealingRules";
import Hierarchy from "@/domain/maintenance/Hierarchy";
import HoldCode from "@/domain/maintenance/HoldCode";
import MaterialBlank from "@/domain/maintenance/material/MaterialBlank";
import MFGFactor from "@/domain/maintenance/MFGFactor";
import MFGRate from "@/domain/maintenance/MFGRate";
import ProjectDefaults from "@/domain/maintenance/ProjectDefaults";
import RecycleCode from "@/domain/maintenance/RecycleCode";
import WCMaint from "@/domain/maintenance/WCMaint";
import ProposalSearchFilters from "@/domain/maintenance/ProposalSearchFilters";
import ToolingRate from "@/domain/maintenance/ToolingRate";
import RoutingPath from "@/domain/maintenance/RoutingPath";
import SampleLocation from "@/domain/proposal/SampleLocation";
import SampleCode from "@/domain/maintenance/SampleCode";
import SampleMaker from "@/domain/maintenance/SampleMaker";
import DunnageAttachment from "@/domain/proposal/DunnageAttachment";
import WorkflowCondition from "@/domain/maintenance/workflow/WorkflowCondition";
import WorkflowGroupAction from "@/domain/maintenance/workflow/WorkflowGroupAction";
import WorkflowGroup from "@/domain/maintenance/workflow/WorkflowGroup";
import ActionStatus from "@/domain/maintenance/workflow/ActionStatus";
import WfFieldDTO from "@/domain/maintenance/workflow/WfFieldDTO";
import WfField from "@/domain/maintenance/workflow/WfField";
import CommentType from "@/domain/proposal/CommentType";
import WorkflowAction from "@/domain/maintenance/workflow/WorkflowAction";
import WfActivityType from "@/domain/maintenance/workflow/WfActivityType";
import ProposalStatus from "@/domain/maintenance/ProposalStatus";
import WorkflowGARule from "@/domain/maintenance/workflow/WorkflowGARule";
import WorkflowRuleDTO from "@/dto/WorkflowRuleDTO";
import User from "@/domain/profile/User";
import {SkillGroup} from "@/domain/maintenance/Skill";
import MomentumProduct from "@/domain/MomentumProduct";
import MomentumCustomer from "@/domain/maintenance/MomentumCustomer";
import Location from "@/domain/maintenance/Location";
import UserLocation from "@/domain/maintenance/UserLocation";
import SampleCarrier from "@/domain/proposal/SampleCarrier";
import MaterialHoldCode from "@/domain/maintenance/material/MaterialHoldCode";
import PriceListHeader from "@/domain/proposal/PriceListHeader";
import TextTemplateItem from "@/domain/maintenance/TextTemplateItem";
import TextTemplateHeader from "@/domain/maintenance/TextTemplateHeader";
import ApiService from "@/service/api/ApiService";


export default class MaintenanceApiService extends ApiService {

    public async getAirCellRules(): Promise<GetGeneralListSuccessResult<AirCellRules>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/air-cell-rules/get-all`, 'get all aircell rules  ' + new Date().toLocaleTimeString());
    }

    public async getCustomerPartTypes(): Promise<GetGeneralListSuccessResult<CustomerPartType>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/customer-part-type/get-all`, "get all customer part types " + new Date().toLocaleTimeString());
    }

    public async getDesignAttentions(): Promise<GetGeneralListSuccessResult<DesignAttention>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/design-attention/get-all`, 'get all design attentions ' + new Date().toLocaleTimeString());
    }

    // Deprecated. Being replaced by ProposalStatuses.
    public async getQaStatuses(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/qa-statuses/get-all`);
    }
    
    public async getProposalStatuses(): Promise<GetGeneralListSuccessResult<ProposalStatus>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/proposal-statuses/get-all`, 'get proposal statuses ' + new Date().toLocaleTimeString());
    }

    public async getProductNumbers(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/product-numbers/get-all`, 'get prodcut numbers ' + new Date().toLocaleTimeString());
    }

    public async getCustomerPartDescs(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/customer-part-descs/get-all`, 'get customer part descriptions ' + new Date().toLocaleTimeString());
    }

    public async getHeatSealingRules(): Promise<GetGeneralListSuccessResult<HeatSealingRules>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/heat-sealing-rules/get-all`, 'get all heat sealing rules ' + new Date().toLocaleTimeString());
    }

    public async getHierarchies(): Promise<GetGeneralListSuccessResult<Hierarchy>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/hierarchy/get-all`, 'get all hierarchies ' + new Date().toLocaleTimeString());
    }

    public async getHoldCodes(): Promise<GetGeneralListSuccessResult<HoldCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/hold-code/get-all`, 'get all hold codes ' + new Date().toLocaleTimeString());
    }

    public async getMaterialHoldCodes(): Promise<GetGeneralListSuccessResult<MaterialHoldCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/material/material-hold-code/get-all`, 'get all material hold codes ' + new Date().toLocaleTimeString());
    }

    public async getMaterialBlanks(): Promise<GetGeneralListSuccessResult<MaterialBlank>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/material-blank/get-all`, 'get all material blanks ' + new Date().toLocaleTimeString());
    }

    public async getMFGFactor(): Promise<GetGeneralListSuccessResult<MFGFactor>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/mfg-factor/get-all`, 'get all mfg factors ' + new Date().toLocaleTimeString());
    }

    public async getMFGRates(): Promise<GetGeneralListSuccessResult<MFGRate>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/mfg-rate/get-all`, 'get all mfg rates ' + new Date().toLocaleTimeString());
    }

    public async getProjectDefaults(): Promise<GetGeneralListSuccessResult<ProjectDefaults>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/project-default/get-all`, 'get all project defaults ' + new Date().toLocaleTimeString());
    }

    public async getRecycleCodes(): Promise<GetGeneralListSuccessResult<RecycleCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/recycle-code/get-all`, 'get all recycle codes ' + new Date().toLocaleTimeString());
    }

    public async getWCMaints(): Promise<GetGeneralListSuccessResult<WCMaint>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/wc-maint/get-all`, 'get all wc maints ' + new Date().toLocaleTimeString());
    }

    public async getReasons(): Promise<GetGeneralListSuccessResult<ReasonCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/reason-code/get-all`, 'get all reasons ' + new Date().toLocaleTimeString());
    }

    public async getPlants(): Promise<GetGeneralListSuccessResult<Plant>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/plant/get-all`, 'get all plants ' + new Date().toLocaleTimeString());
    }

    public async getProposalSearchFilters(): Promise<GetGeneralListSuccessResult<ProposalSearchFilters>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/proposal-search-filters/get-all`, 'get all proposal search filters ' + new Date().toLocaleTimeString());
    }

    public async getAllContainerModels(): Promise<GetGeneralListSuccessResult<ContainerModel>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/container-models/get-all`, 'get all container models ' + new Date().toLocaleTimeString());
    }

    public async getAllWorkCenters(): Promise<GetGeneralListSuccessResult<WorkCenter>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/work-center/get-all`, 'get all workcenters ' + new Date().toLocaleTimeString());
    }

    public async getAllWCOperations(): Promise<GetGeneralListSuccessResult<WCOperation>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/wc-operation/get-all`, 'get all wc operations ' + new Date().toLocaleTimeString());
    }

    async getAllAmortCodes(): Promise<GetGeneralListSuccessResult<AmortCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/amort-code/get-all`, 'get all amort codes ' + new Date().toLocaleTimeString());
    }

    /*pull from search endpoint*/
    async getAllMaterials(): Promise<GetGeneralListSuccessResult<AllMaterial>> {
        return this.handleGetListRequest(`${BASE_API_URL}/search/material/get-all`, 'get all materials ' + new Date().toLocaleTimeString());
    }

    async getMaterial(materialNumber: string, plantNumber: string): Promise<GetSingleItemResult<AllMaterial>> {
        return this.handleGetRequest(`${BASE_API_URL}/material/getMaterialByNumber/${materialNumber}/${plantNumber}`, "Failed to Find Material")
    }

    async getMaterialUseCodes(): Promise<GetGeneralListSuccessResult<MaterialUseCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/material/use-codes/get`, 'get all material use codes ' + new Date().toLocaleTimeString());
    }

    async getAllProjectTypeCodes(): Promise<GetGeneralListSuccessResult<ProjectTypeCode>> {
        const response = await this.handleGetListRequest<ProjectTypeCode>(
            `${BASE_API_URL}/maintenance/project-type-code/get-all`,
            'get all project type codes ' + new Date().toLocaleTimeString()
        );

        if(response.items) {
            const codes: ProjectTypeCode[] = [];
            response.items.forEach((rawData) => {codes.push(new ProjectTypeCode(rawData))})
            response.items = codes;
        }
        return response
    }

    public async getToolingRates(): Promise<GetGeneralListSuccessResult<ToolingRate>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/tooling-rate/get-all`, 'get all tooling rates ' + new Date().toLocaleTimeString());
    }

    public async getRoutingPaths(): Promise<GetGeneralListSuccessResult<RoutingPath>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/routing-path/get-all`, 'get all routing paths ' + new Date().toLocaleTimeString());
    }

    public async getCurrencies(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/currencies/get-all`, 'get all currencies ' + new Date().toLocaleTimeString());
    }

    public async getCustomerNames(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/customerNames/get-all`, 'get customer names ' + new Date().toLocaleTimeString());
    }

    public async getSampleAddresses(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleAddresses/get-all`, 'get sample addresses ' + new Date().toLocaleTimeString());
    }

    public async getSampleCities(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleCities/get-all`, 'get sample cities ' + new Date().toLocaleTimeString());
    }

    public async getSampleStates(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleStates/get-all`, 'get sample states ' + new Date().toLocaleTimeString());
    }

    public async getSampleCountries(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleCountries/get-all`, 'get sample countries ' + new Date().toLocaleTimeString());
    }

    public async getSampleZips(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleZips/get-all`, 'get sample zips ' + new Date().toLocaleTimeString());
    }

    public async getSampleAttentionTos(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleAttentionTos/get-all`, 'get sample attention to-s ' + new Date().toLocaleTimeString());
    }

    public async getSamplePhones(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/samplePhones/get-all`, 'get sample phones ' + new Date().toLocaleTimeString());
    }

    public async getProjectManagers(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/projectManagers/get-all`, 'get project managers ' + new Date().toLocaleTimeString());
    }

    public async getRamSam(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/ramSam/get-all`, 'get ram sam ' + new Date().toLocaleTimeString());
    }

    public async getCrmCsm(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/crmCsm/get-all`, 'get crm csm ' + new Date().toLocaleTimeString())
    }

    public async getActiveUsers(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/users/get-active`, 'get active users ' + new Date().toLocaleTimeString());
    }

    public async getApprovedBy(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/approvedBy/get-all`, 'get approved by ' + new Date().toLocaleTimeString());
    }

    public async getDesigners(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/designers/get-all`, 'get designers ' + new Date().toLocaleTimeString());
    }

    public async updateDesigner(designer: User): Promise<GetSingleItemResult<User>> {
        const data = JSON.stringify(designer);
        return this.handlePostRequestReturningItem(`${BASE_API_URL}/maintenance/designers/update`,
            data,
            {'Content-Type': 'application/json'},
            'update designers ' + new Date().toLocaleTimeString()
        );
    }

    public async getEstimators(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/estimators/get-all`, 'get estimators ' + new Date().toLocaleTimeString());
    }

    public async getSampleLocations(): Promise<GetGeneralListSuccessResult<SampleLocation>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleLocations/get-all`, 'get sample location ' + new Date().toLocaleTimeString())
    }

    public async getSampleCodes(): Promise<GetGeneralListSuccessResult<SampleCode>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleCodes/get-all`, 'get sample codes ' + new Date().toLocaleTimeString());
    }

    public async getSampleMakers(): Promise<GetGeneralListSuccessResult<SampleMaker>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleMakers/get-all`, 'get sample makers ' + new Date().toLocaleTimeString());
    }

    public async getDunnageAttachments(): Promise<GetGeneralListSuccessResult<DunnageAttachment>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/dunnageAttachments/get-all`, 'get dunnage attachments ' + new Date().toLocaleTimeString())
    }

    public async getCommentTypes(): Promise<GetGeneralListSuccessResult<CommentType>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/commentTypes/get-all`, 'get comment types ' + new Date().toLocaleTimeString())
    }

    public async getSampleStatuses(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sampleStatus/get-all`, 'get sample statuses ' + new Date().toLocaleTimeString());
    }

    public async getPriceHoldList(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/priceHolds/get-all`, 'get price hold list ' + new Date().toLocaleTimeString());
    }

    /**
     *
     * @param item - item to insert
     * @param className - classname of the maintenance item. Hardcoded value that must match a value in DomainMaintenanceFactory.java. Case insensitive.
     */
    async insertMaintenanceItem<T>(item: T, className: MaintenanceClassName): Promise<GetSingleItemResult<T>> {
        const data = JSON.stringify(item);
        return this.handlePostRequestReturningItem(`${BASE_API_URL}/maintenance/modify/insert/${className}`,
            data,
            {'Content-Type': 'application/json'},
            'add item ' + new Date().toLocaleTimeString()
        )
    }

    /**
     *
     * @param item - item to update
     * @param className - classname of the maintenance item. Hardcoded value that must match a value in DomainMaintenanceFactory.java. Case insensitive.
     * @param rowIdentifier - String representation of the primary key value of this row. Composite PK's are comma delineated
     */
    async updateMaintenanceItem<T>(item: T, className: MaintenanceClassName, rowIdentifier: String): Promise<GetSingleItemResult<T>> {
        const data = JSON.stringify(item);
        return this.handlePostRequestReturningItem(`${BASE_API_URL}/maintenance/modify/update/${className}/${rowIdentifier}`,
            data,
            {'Content-Type': 'application/json'},
            'edit item ' + new Date().toLocaleTimeString()
        )
    }

    async deleteMaintenanceItem<T>(item: T, className: MaintenanceClassName): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(item);
        return this.handlePostRequestReturningItem(`${BASE_API_URL}/maintenance/modify/delete/${className}`,
            data,
            {'Content-Type': 'application/json'},
            'delete item ' + new Date().toLocaleTimeString()
        );
    }

    public async getAllWorkflowGroups(): Promise<GetGeneralListSuccessResult<WorkflowGroup>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflows/group/get-all`, 'get all workflow groups ' + new Date().toLocaleTimeString());
    }

    public async duplicateGroupAction(actionId: number): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(actionId);
        return this.handlePostRequestReturningArray(`${BASE_API_URL}/maintenance/workflows/group/group-action/copy`,
            data,
            {'Content-Type': 'application/json'},
            'duplicate group actions ' + new Date().toLocaleTimeString()
        );
    }

    public async deleteGroupAction(action: WorkflowGroupAction): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(action);
        return this.handlePostRequestReturningArray(`${BASE_API_URL}/maintenance/workflows/group/group-action/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete group action ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteWorkflowRule(rule: WorkflowGARule): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        const data = JSON.stringify(rule);
        return this.handlePostRequestReturningItem(`${BASE_API_URL}/maintenance/workflows/group/rule/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete workflow rule ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteWorkflowCondition(condition: WorkflowCondition): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(condition);
        return this.handlePostRequestReturningArray(`${BASE_API_URL}/maintenance/workflows/group/condition/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete workflow condition ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteWorkflowAction(workflowAction: WorkflowAction): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowAction);
        return this.handlePostRequestReturningArray(`${BASE_API_URL}/maintenance/workflows/group/action/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete workflow action ' + new Date().toLocaleTimeString()
        )
    }

    public async getWorkflowGARulesByActionId(workflowGroupActionId: number): Promise<GetGeneralListSuccessResult<WorkflowGARule>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflow/group/rule/` + workflowGroupActionId, 'get workflow GA rules by action id ' + new Date().toLocaleTimeString());
    }

    public async getWorkflowGARules(): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        return this.handleGetRequest(`${BASE_API_URL}/maintenance/workflow/group/rule/get-all`, 'get workflow GA rules ' + new Date().toLocaleTimeString());
    }

    public async getAllWorkflowGroupActions(): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflows/group/group-action/get-all`, 'get all workflow group actions ' + new Date().toLocaleTimeString())
    }

    public async getWorkflowConditions(workflowGroupRuleId: number): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflow/group/condition/` + workflowGroupRuleId)
    }

    public async getAllWorkflowConditions(): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflow/group/condition/get-all`, 'get all workflow conditions ' + new Date().toLocaleTimeString());
    }

    public async copyWorkflowCondition(cond: WorkflowCondition): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(cond);
        return this.handlePostRequestReturningArray(`${BASE_API_URL}/maintenance/workflows/condition/copy`,
            data,
            {'Content-Type': 'application/json'},
        )
    }

    public async copyWorkflowActions(workflowActionId: number): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowActionId);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/action/copy`,
            data,
            {'Content-Type': 'application/json'}
        )
    }

    public async copyWorkflowActionRule(workflowGARule: WorkflowGARule): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        const data = JSON.stringify(workflowGARule);
        return this.handlePostRequestReturningItem(
            `${BASE_API_URL}/maintenance/workflows/group/rule/copy`,
            data,
            {'Content-Type': 'application/json'},
            'copy workflow rule ' + new Date().toLocaleTimeString()
        )
    }

    public async getAllWorkflowActionStatuses(): Promise<GetGeneralListSuccessResult<ActionStatus>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflows/group/group-action/status/get-all`)
    }

    public async getAllWorkflowActions(): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflows/group/action/get-all`, 'get all workflow actions ' + new Date().toLocaleTimeString());
    }

    public async getAllWfActivityTypes(): Promise<GetGeneralListSuccessResult<WfActivityType>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/workflows/group/action/types/get-all`)
    }

    public async saveWorkflowAction(workflowGroupAction: WorkflowGroupAction): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(workflowGroupAction);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/group/group-action/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow action ' + new Date().toLocaleTimeString()
        )
    }

    public async saveWorkflowFields(workflowFields: Array<WfField>): Promise<GetGeneralListSuccessResult<WfField>> {
        const data = JSON.stringify(workflowFields);
        return this.handlePostRequestReturningArray(
           `${BASE_API_URL}/maintenance/workflows/field/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow fields ' + new Date().toLocaleTimeString()
        )
    }

    public async saveWorkflowConditions(workflowActions: Array<WorkflowCondition>): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(workflowActions);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/group/condition/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow action conditions ' + new Date().toLocaleTimeString()
        )
    }

    public async getWorkflowFieldData(): Promise<GetSingleItemResult<WfFieldDTO>> {
        return this.handleGetRequest(`${BASE_API_URL}/maintenance/workflows/field-data/get-all`, 'get workflow field data ' + new Date().toLocaleTimeString())
    }

    public async validateWorkflowFieldData(): Promise<GetSingleItemResult<String>> {
        return this.handleGetRequest(`${BASE_API_URL}/maintenance/workflows/field-data/validate`, 'validate workflow field data ' + new Date().toLocaleTimeString());
    }

    public async saveWorkflowGARules(workflowGARules: Array<WorkflowGARule>): Promise<GetGeneralListSuccessResult<WorkflowGARule>> {
        const data = JSON.stringify(workflowGARules);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/group/rule/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow GA rules ' + new Date().toLocaleTimeString()
        )
    }

    public async saveWorkflowActions(workflowActions: Array<WorkflowAction>): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowActions);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/group/action/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow actions ' + new Date().toLocaleTimeString()
        )
    }

    public async saveWorkflowGroups(workflowGroups: Array<WorkflowGroup>): Promise<GetGeneralListSuccessResult<WorkflowGroup>> {
        const data = JSON.stringify(workflowGroups);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/workflows/group/update`,
            data,
            {'Content-Type': 'application/json'},
            'save workflow groups ' + new Date().toLocaleTimeString()
        )
    }

    public async getAllSampleCarriers(): Promise<GetGeneralListSuccessResult<SampleCarrier>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/sample-carriers/get-all`)
    }

    public async getAllSkillGroups(): Promise<GetGeneralListSuccessResult<SkillGroup>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/skillGroups/get-all`, 'get all skill groups ' + new Date().toLocaleTimeString());
    }

    public async getAllMomentumProducts(): Promise<GetGeneralListSuccessResult<MomentumProduct>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/momentumProductTypes/get-all`, 'get all momentum products ' + new Date().toLocaleTimeString());
    }

    public async getAllMomentumCustomers(): Promise<GetGeneralListSuccessResult<MomentumCustomer>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/momentumCustomers/get-all`, 'get all momentum customers ' + new Date().toLocaleTimeString());
    }

    public async addMomentumCustomer(customer: MomentumCustomer): Promise<GetSingleItemResult<MomentumCustomer>> {
        const data = JSON.stringify(customer);
        return this.handlePostRequestReturningItem(
            `${BASE_API_URL}/maintenance/momentumCustomers/add`,
            data,
            {'Content-Type': 'application/json'},
            'add momentum customer ' + new Date().toLocaleTimeString()
        )
    }

    public async addMomentumProductType(productType: MomentumProduct): Promise<GetSingleItemResult<MomentumProduct>> {
        const data = JSON.stringify(productType);
        return this.handlePostRequestReturningItem(
            `${BASE_API_URL}/maintenance/momentumProductTypes/add`,
            data,
            {'Content-Type': 'application/json'},
            'add momentum product type ' + new Date().toLocaleTimeString()
        )
    }

    public async removeMomentumCustomer(customerID: number): Promise<GetGeneralSuccessResult> {
        return this.handlePostRequestReturningSuccess(`${BASE_API_URL}/maintenance/momentumCustomers/remove/${customerID}`, 'remove momentum customer ' + new Date().toLocaleTimeString());
    }

    public async removeMomentumProductType(productTypeID: number): Promise<GetGeneralSuccessResult> {
        return this.handlePostRequestReturningSuccess(
            `${BASE_API_URL}/maintenance/momentumProductTypes/remove/${productTypeID}`,
        'remove momentum product type ' + new Date().toLocaleTimeString()
        )
    }

    public async updateMomentumCustomer(customer: MomentumCustomer): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(customer);
        return this.handlePostRequestReturningSuccess(`${BASE_API_URL}/maintenance/momentumCustomers/update`,
            'update momentum customer ' + new Date().toLocaleTimeString(),
            data,
            {'Content-Type': 'application/json'}
        )
    }

    public async getLocations(): Promise<GetGeneralListSuccessResult<Location>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/location/get-all`, 'get location ' + new Date().toLocaleTimeString());
    }

    public async getUserLocations(): Promise<GetGeneralListSuccessResult<UserLocation>> {
        return this.handleGetListRequest(`${BASE_API_URL}/users/user-location/get-all`, 'get user locations ' + new Date().toLocaleTimeString());
    }


    public async addLocation(location: Location): Promise<GetGeneralListSuccessResult<Location>> {
        const data = JSON.stringify(location);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/location/update`,
            data,
            {'Content-Type': 'application/json'},
            'add location ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteLocation(locationId: number): Promise<GetGeneralListSuccessResult<Location>> {
        const data = JSON.stringify(locationId);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/maintenance/location/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete location ' + new Date().toLocaleTimeString()
        )
    }

    public async addUserLocations(userLocations: Array<UserLocation>): Promise<GetGeneralListSuccessResult<UserLocation>> {
        const data = JSON.stringify(userLocations);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/users/user-location/add`,
            data,
            {'Content-Type': 'application/json'},
            'add user locations ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteUserLocation(userLocationId: number): Promise<GetGeneralListSuccessResult<UserLocation>> {
        const data = JSON.stringify(userLocationId);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/users/user-location/delete`,
            data,
            {'Content-Type': 'application/json'},
            'delete user location ' + new Date().toLocaleTimeString()
        )
    }

    public async getPriceListHeaders(): Promise<GetGeneralListSuccessResult<PriceListHeader>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/priceListHeader/get-all`, 'get pricelist headers ' + new Date().toLocaleTimeString());
    }

    public async getTextTemplateHeaders(): Promise<GetGeneralListSuccessResult<TextTemplateHeader>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/text-template-header/get-all`, 'get text template headers ' + new Date().toLocaleTimeString());
    }

    public async getTextTemplateItems(): Promise<GetGeneralListSuccessResult<TextTemplateItem>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/text-template/get-all`, 'get text template ' + new Date().toLocaleTimeString());
    }

    public async getEffortCodeOptions(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/effort-codes/get-all`, 'get effort codes ' + new Date().toLocaleTimeString());
    }

    public async getFOBDescriptions(): Promise<GetGeneralListSuccessResult<string>> {
        return this.handleGetListRequest(`${BASE_API_URL}/maintenance/fob-description/get-all`, 'get fob descriptions ' + new Date().toLocaleTimeString());
    }
}