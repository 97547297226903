import {GetGeneralListSuccessResult, GetGeneralSuccessResult} from "@/service/api/types/ApiResponseTypes";
import {BASE_API_URL} from "@/config/env";
import {SaveableUITablePreferencesDTO} from "@/dto/SaveableUITablePreferencesDTO";
import ApiService from "@/service/api/ApiService";

export default class UserApiService extends ApiService{

    public async getUserOrderableTableSetups(userId: number): Promise<GetGeneralListSuccessResult<SaveableUITablePreferencesDTO>> {
        return this.handleGetListRequest(
            `${BASE_API_URL}/users/get-table-preferences/` + userId,
            'get user orderable table setups ' + new Date().toLocaleTimeString()
        )
    }

    public async saveUserTableColumnSetup(tableColumnOrderDTO: SaveableUITablePreferencesDTO): Promise<GetGeneralListSuccessResult<SaveableUITablePreferencesDTO>> {
        const data = JSON.stringify(tableColumnOrderDTO);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/users/table-preferences/update`,
            data,
            {'Content-Type': 'application/json'},
            'save user table column order ' + new Date().toLocaleTimeString()
        )
    }

    public async deleteUserTableColumnOrder(tablePreferences: SaveableUITablePreferencesDTO): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(tablePreferences);
        return this.handlePostRequestReturningSuccess(
            `${BASE_API_URL}/users/table-preferences/delete`,
            'delete table column order ' + new Date().toLocaleTimeString(),
            data,
            {'Content-Type': 'application/json'}
        )
    }
}