import LoadingStore from "@/store/LoadingStore";
import ProfileStore from "@/store/ProfileStore";
import rootStore from "@/store/RootStore";
import SaveableSearch, {SaveableSearchDTO} from "@/dto/SaveableSearchDTO";
import {useNotification} from "@/views/core/functions/useNotification";
import SearchApiService from "@/service/api/SearchApiService";
import ProposalSearchResultDTO from "@/dto/ProposalSearchResultDTO";
import {GetResultSetWithCountDTO} from "@/service/api/types/ApiResponseTypes";
import ProposalSearchFilter from "@/components/filters/ProposalSearchFilter";
import MaterialFilter from "@/components/filters/MaterialFilter";
import AllMaterial from "@/domain/maintenance/material/AllMaterial";
import ProductionStore from "@/store/ProductionStore";
import LogService from "@/service/LogService";

// Handles saving & retrieving search parameters as well as retrieving search results. Currently only supports these as needed for the proposal search tools.
export default class SearchService {
    private loadingStore: LoadingStore;
    private profileStore: ProfileStore;
    private productionStore: ProductionStore;
    private searchApiService: SearchApiService;
    private logService;

    constructor() {
        this.profileStore = rootStore.getInstance().profileStore;
        this.loadingStore = rootStore.getInstance().loadingStore;
        this.productionStore = rootStore.getInstance().productionStore;
        this.searchApiService = new SearchApiService();
        this.logService = new LogService();
    }

    // Fetch search results if arguments given.
    public async getProposalSearchResults(proposalSearchFilter: ProposalSearchFilter): Promise<GetResultSetWithCountDTO<ProposalSearchResultDTO>> {
        return await this.searchApiService.getProposalSearchResults(proposalSearchFilter);
    }

    // retrieves all saved searches for which current user has permission (ownership and shared)
    public async getAllSearchesWithPermission(): Promise<{success: boolean, ownSearches: SaveableSearch[], sharedSearches: SaveableSearch[]}> {
        const response = await this.searchApiService.getAllSearchesWithPermission(this.profileStore.userID);
        const ownSearches: SaveableSearch[] = [];
        const sharedSearches: SaveableSearch[] = [];
        if(response.success) {
            response.ownSearches.forEach((searchDTO) => {
                ownSearches.push(new SaveableSearch({
                    searchID: searchDTO.searchID,
                    searchName: searchDTO.searchName,
                    ownerID: searchDTO.ownerID,
                    editable: searchDTO.editable,
                    tableName: searchDTO.tableName,
                    searchParameters: JSON.parse(searchDTO.searchParameters),
                    sharedWith: searchDTO.sharedWith
                }));
            })
            response.sharedSearches.forEach((searchDTO) => {
                sharedSearches.push(new SaveableSearch({
                    searchID: searchDTO.searchID,
                    searchName: searchDTO.searchName,
                    ownerID: searchDTO.ownerID,
                    editable: searchDTO.editable,
                    tableName: searchDTO.tableName,
                    searchParameters: JSON.parse(searchDTO.searchParameters),
                    sharedWith: searchDTO.sharedWith
                }));
            })
        } else {
            const customHeader = 'Get all searches with permissions ' + new Date().toLocaleTimeString();
            const loggableError = this.logService.generateLoggableErrorFromAPIResponse(response, customHeader);
            await this.logService.logEvent(loggableError)
        }
        return {success: response.success, ownSearches, sharedSearches};
    }

    // saves a proposal search to database
    public async saveProposalSearch(dto: SaveableSearchDTO): Promise<number> {
        this.loadingStore.isBusy = true;
        const response = await this.searchApiService.saveProposalSearch(dto);
        if(response.success){
            useNotification().showSimple("Saved");
        }
        else {
            useNotification().showSimple("Error");
        }
        this.loadingStore.isBusy = false;
        return response.number? response.number : 0;
    }

    public async updateSearchSharePermissions(search: SaveableSearch) {
     if(search) {
         this.loadingStore.isBusy = true;
         const response = await this.searchApiService.updateSearchSharePermissions(search.toDTO());
         this.loadingStore.isBusy = false;
         if(response.success) {
             useNotification().showSimple("Saved")
             return true
         } else {
             useNotification().showSimple("Error");
             const customHeader = 'update search share permissions ' + new Date().toLocaleTimeString();
             const loggableError = this.logService.generateLoggableErrorFromAPIResponse(response, customHeader);
             await this.logService.logEvent(loggableError)
             return false;
         }
     } else {
         useNotification().showSimple("Error generating search object");
         return false;
     }
    }

    public async getMaterialSearchResults(materialFilter: MaterialFilter): Promise<GetResultSetWithCountDTO<AllMaterial>> {
        const response = await this.searchApiService.getMaterialSearchResults(materialFilter);
        if(!response.success) {
            const customHeader = 'Get material search results ' + new Date().toLocaleTimeString();
            const loggableError = this.logService.generateLoggableErrorFromAPIResponse(response, customHeader);
            await this.logService.logEvent(loggableError)
        }
        return response;
    }

    public async getDefaultMaterialSearchResults(): Promise<GetResultSetWithCountDTO<AllMaterial>> {
        const response = await this.searchApiService.getDefaultMaterialSearchResults();
        if(!response.success) {
            const customHeader = 'get default material search results ' + new Date().toLocaleTimeString();
            const loggableError = this.logService.generateLoggableErrorFromAPIResponse(response, customHeader);
            await this.logService.logEvent(loggableError)
        }
        return response;
    }

    public async getProposalSimpleSearchResults(): Promise<boolean> {
        const response = await this.searchApiService.getProposalSimpleSearchResults();
        if (response.success) {
            this.productionStore.proposalDTOs = response.resultSet;
        } else {
            const customHeader = 'get proposal simple search results ' + new Date().toLocaleTimeString();
            const loggableError = this.logService.generateLoggableErrorFromAPIResponse(response, customHeader);
            await this.logService.logEvent(loggableError)
        }
        return response.success;
    }
}