
export default class Group {
    public userGroupId: number = 0;
    public userGroupName: string = '';
    public userGroupDesc: string = '';

    constructor(init?: Partial<Group>) {
        if(init) Object.assign(this, init);
    }

    public hasRestrictedAccess(): boolean {
        return this.userGroupName.toLowerCase() === 'sensitivedata';
    }

    public hasSpoofingPermission() {
        return this.userGroupName.toLowerCase() === 'momentum admins'
            || this.userGroupName.toLowerCase() === 'momentum admin'
            || this.userGroupName.toLowerCase() === 'momentumadmin'
            || this.userGroupName.toLowerCase() === 'uat testers';
    }


}