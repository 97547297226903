import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cb8274a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "headerBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_progress = _resolveComponent("loading-progress")!
  const _component_loading = _resolveComponent("loading")!
  const _component_re_auth = _resolveComponent("re-auth")!
  const _component_spoof_user_menu = _resolveComponent("spoof-user-menu")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_profile_summary = _resolveComponent("profile-summary")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_navbar = _resolveComponent("b-navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_side_bar_nav = _resolveComponent("side-bar-nav")!
  const _component_side_bar_log = _resolveComponent("side-bar-log")!
  const _component_alert = _resolveComponent("alert")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass({'busy': _ctx.loadingStore.isBusy })
  }, [
    (_ctx.loadingStore.isLoadingProgress)
      ? (_openBlock(), _createBlock(_component_loading_progress, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.loadingStore.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.profileStore.reauthRequired)
      ? (_openBlock(), _createBlock(_component_re_auth, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.spoofModalOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.spoofModalOpen) = $event)),
      "hide-footer": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_spoof_user_menu, {
          onCancel: _cache[0] || (_cache[0] = () => {_ctx.state.spoofModalOpen=false;})
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (!_ctx.state.routesHideSideAndNavBar.includes(_ctx.$route.name))
      ? (_openBlock(), _createBlock(_component_b_navbar, {
          key: 3,
          type: "dark",
          class: _normalizeClass(['orbis-nav', _ctx.$route.name.includes('New') ? 'new-nav-bg' : '']),
          toggleable: "lg"
        }, {
          default: _withCtx(() => [
            (!_ctx.$route.name.includes('New'))
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "fa-solid fa-list",
                  class: "btn-toggle-nav",
                  onClick: _ctx.toggleNav
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              id: "appHeader",
              class: _normalizeClass([_ctx.$route.name.includes('New') ? 'new-proposal-header' : 'old-ui'])
            }, null, 2),
            _createElementVNode("div", _hoisted_1, [
              (!_ctx.$route.name.includes('New'))
                ? (_openBlock(), _createBlock(_component_b_dropdown, {
                    key: 0,
                    "no-caret": "",
                    left: ""
                  }, {
                    "button-content": _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-user" })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_profile_summary, { "has-footer-slot": "" }, {
                        "footer-content": _withCtx(() => [
                          _createVNode(_component_b_button, {
                            class: "slim-padding-all",
                            style: {"margin":"5px"},
                            onClick: _ctx.logout
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Log Off")
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          (_ctx.state.canSpoofUserGroups)
                            ? (_openBlock(), _createBlock(_component_b_button, {
                                key: 0,
                                class: "slim-padding-all",
                                style: {"margin":"5px"},
                                onClick: _cache[2] || (_cache[2] = () => {_ctx.state.spoofModalOpen = true;})
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Spoof Groups ")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.state.isSpoofingUserGroups)
                            ? (_openBlock(), _createBlock(_component_b_button, {
                                key: 1,
                                class: "slim-padding-all",
                                style: {"margin":"5px"},
                                onClick: _ctx.endSpoof
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Stop Spoofing")
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    (!_ctx.loadingStore.isLoadingProgress)
      ? (_openBlock(), _createBlock(_component_side_bar_nav, {
          key: 4,
          style: {"padding-right":"30px"}
        }, {
          content: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.$route.name.includes('New'))
      ? (_openBlock(), _createBlock(_component_side_bar_log, {
          key: 5,
          hide: false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_alert)
  ], 2))
}