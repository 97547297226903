import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_base = _resolveComponent("dropdown-autocomplete-base")!

  return (_openBlock(), _createBlock(_component_dropdown_autocomplete_base, _mergeProps(_ctx.$props, {
    "model-value": _ctx.modelValue,
    onOnSelect: _ctx.handleSelect,
    onOnClear: _ctx.handleClear,
    "is-new-ui": _ctx.isNewUi
  }), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((slotData) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData || {})))
        ])
      }
    })
  ]), 1040, ["model-value", "onOnSelect", "onOnClear", "is-new-ui"]))
}