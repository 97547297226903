import SampleCarrier from "@/domain/proposal/SampleCarrier";

export default class Sample {
    public sampleID!: number;
    public proposalID!: number;
    public productID!: number;
    public address!: string;
    public address2!: string;
    public attentionTo!: string;
    public city!: string;
    public country!: string;
    public phone!: string;
    public carrier!: number; // SampleCarrierID
    public code!: string;
    public effortCode!: string;
    public location?: number | null | undefined;
    public makerNotes!: string;
    public price!: number;
    public weight!: number;
    public state!: string;
    public trackingNumber!: string;
    public typeOfSample!: string;
    public vendorItemsNeeded!: string;
    public zip!: string;
    public salesOrderNumber!: string;
    public useDefaultAddress = false; // required field in db, default to false
    public containerRequired!: boolean;
    public sampleStatus!: string;
    public shipToCustomer!: string;
    public sampleAssemblyTime!: number;

    constructor(init?: Partial<Sample>) {
        Object.assign(this, init);
    }
}
