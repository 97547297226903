import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-456758e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "b-form-input" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["disabled", "readonly", "value", "rows", "maxlength"]
const _hoisted_4 = ["disabled", "readonly", "value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (!_ctx.fillContainer)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          id: "exampleFormControlTextarea1",
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          value: _ctx.modelValue,
          class: "form-control",
          style: {"font-size":"1em"},
          rows: _ctx.rows,
          maxlength: _ctx.maxLength,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange($event)))
        }, null, 40, _hoisted_3))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          id: "exampleFormControlTextarea1",
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          value: _ctx.modelValue,
          class: "form-control",
          style: {"font-size":"1em","height":"100%"},
          maxlength: _ctx.maxLength,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput($event))),
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange($event)))
        }, null, 40, _hoisted_4))
  ]))
}