
import {computed, defineComponent, onMounted, reactive, watch} from "vue";
import rootStore from "@/store/RootStore";
import BButton from "@/components/bootstrap-library/BButton.vue";
import LogService from "@/service/LogService";
import LoggableError from "@/service/api/types/LoggableError";

type State = {
  screenWidth: number;
  error: boolean;
  loading: boolean;
}

export default defineComponent({
  name: "SideBarLog",
  components: {BButton},
  props: {
    hide: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, context) {

    const state = reactive<State>({
      screenWidth: 0,
      error: true,
      loading: true,
    })

    const sidebar = rootStore.getInstance().sidebarLogStore;
    const logService = new LogService();

    watch(() => [props.hide], (val) => {
      if (val) sidebar.sideBarVisible = false;
    });

    onMounted(() => {
      let showSidebar = sessionStorage.getItem('sidebarlog.sideBarVisible');
      showSidebar === 'true' ? sidebar.sideBarVisible = true : sidebar.sideBarVisible = false;
      if (showSidebar) {
        if (sidebar.sideBarVisible) {
          if (!props.hide) {
            //we're good
          } else {
            sidebar.toggleSidebar();
          }
        }
      }
      state.loading = false;
    })

    const mobile = computed((): boolean => {
      return state.screenWidth <= sidebar.mobileDesktopBreak;
    })

    const sideBarWidth = computed((): number => {
      if (!mobile.value) {
        return sidebar.sideBarWidthDesktop;
      }
      return sidebar.sideBarWidthMobile;
    })

    const sideBarOpenCss = computed(() => {
      return {
        width: sideBarWidth.value + 'px',
        right: 0,
      };
    })

    const sideBarCloseCss = computed(() => {
      return {
        width: sideBarWidth.value / 10 + 'px',
        transition: 'all 0.3s',
        right: 0,
      };
    })

    const errors = computed(() => {
      return sidebar.errors;
    })

    function logClick() {
      sidebar.toggleSidebar();
    }

    function toggleView(item: LoggableError) {
      item.expanded = !item.expanded;
    }

    function clearLog() {
      logService.clearLog();
    }

    return {
      state,
      sidebar,
      sideBarOpenCss,
      sideBarCloseCss,
      logClick,
      errors,
      toggleView,
      clearLog
    }
  }
})

