import {BASE_API_URL} from "@/config/env";
import User from "@/domain/profile/User";
import Group from "@/domain/profile/Group";
import ApiService from "@/service/api/ApiService";
import {
    GetGeneralListSuccessResult,
    GetSingleItemResult
} from "@/service/api/types/ApiResponseTypes";

export default class UserGroupApiService extends ApiService{

    public async getAllUsers(): Promise<GetGeneralListSuccessResult<User>> {
        return this.handleGetListRequest(
            `${BASE_API_URL}/users/get-users/all`,
            'get all users ' + new Date().toLocaleTimeString()
        )
    }

    public async getAllGroups(): Promise<GetGeneralListSuccessResult<Group>> {
        const result = await this.handleGetListRequest<Group>(
            `${BASE_API_URL}/users/get-groups/all`,
            'get all groups ' + new Date().toLocaleTimeString()
        )
        const groupArr: Array<Group> = [];
        if(result.items) {
            result.items.forEach((groupLiteral) => {groupArr.push(new Group(groupLiteral))}); // Create objects from JSON literals
        }
        result.items = groupArr;
        return result;
    }

    public async getSpoofedGroups(groups: Array<number>): Promise<GetGeneralListSuccessResult<Group>> {
        const data = JSON.stringify(groups);
        return await this.handlePostRequestReturningArray(`${BASE_API_URL}/users/get-groups/spoof`,
            data,
            {'Content-Type': 'application/json'},
            'get all groups ' + new Date().toLocaleTimeString()
        )
    }

    public async getSpoofAllowedStatus(): Promise<GetSingleItemResult<boolean>> {
        return await this.handleGetRequest<boolean>(
            `${BASE_API_URL}/users/spoof/isallowed`,
            'get proposal by number ' + new Date().toLocaleTimeString()
        );
    }
}