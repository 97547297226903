import TimeZoneTranslator from "@/utility/TimeZoneTranslator";
import ProposalCommentLog from "@/domain/proposal/ProposalCommentLog";

export default class ProposalComment {
    public commentID = 0;
    public productNumber!: string;
    public comment!: string;
    public opportunity!: string;
    public revision!: number;
    public lastModified!: string; // timestamp
    public modifiedBy!: string; // Joined from momentum users table
    public userId!: number;
    public commentTypeID!: number | undefined;
    public commentTypeName!: string | undefined; // Joined from comment types table
    public proposalID!: string;
    public active!: boolean;
    public proposalCommentLog!: ProposalCommentLog
    public emailedLog!: Array<ProposalCommentLog>;

    constructor(init?: Partial<ProposalComment>) {
        Object.assign(this, init);
        this.proposalCommentLog = new ProposalCommentLog(init?.proposalCommentLog);
        if(init?.emailedLog && init.emailedLog.length > 0) this.emailedLog = ProposalComment.initCommentLogs(init.emailedLog);
        this.fixLocalTimesToUTC();
    }

    public fixLocalTimesToUTC(): void {
        this.lastModified = TimeZoneTranslator.toClientTime(this.lastModified);
    }

    private static initCommentLogs(emailLogs: ProposalCommentLog[]): Array<ProposalCommentLog> {
        const array: ProposalCommentLog[] = [];
        for(const emailLog of emailLogs) {
            array.push(new ProposalCommentLog(emailLog));
        }
        return array;
    }

}
