
import { defineComponent, PropType } from 'vue';

type ItemType = {
    label: string;
    value: string;
}

export default defineComponent({
    name: 'proposal-field-value',
    props: {
        item: {
            type: Object as PropType<ItemType>,
            required: true
        }
    },
    data() {
        return {
        };
    },
    setup(props, context) {

        return {
        }
    }
});
