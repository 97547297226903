
import {computed, defineComponent, reactive, ref, Ref, watch} from "vue";
import rootStore from "@/store/RootStore";
import Group from "@/domain/profile/Group";
import BRow from "@/components/bootstrap-library/BRow.vue";
import BCol from "@/components/bootstrap-library/BCol.vue";
import CheckboxInput from "@/views/core/components/inputs/CheckboxInput.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
export default defineComponent({
  name: "SpoofUserMenu",
  props: {
  },
  components: {BButton, CheckboxInput, BCol, BRow},
  setup(props, context) {
    const allGroups = rootStore.getInstance().userGroupStore.groups;
    const rowLength = 4;

    const groupMatrix = computed( () => {
          const matrix: Array<Array<{group: Group,active: boolean}>> = [];
          for(let i=0; i<allGroups.value.length/rowLength; i++) {
            matrix.push([]);
            for(let j=0; j<rowLength; j++) {
              if(i*rowLength+j < allGroups.value.length) {
                matrix[i].push({group: allGroups.value[i*rowLength+j], active: false});
              }
            }
          }
      return matrix;
    })

    function generateGroupListJSON(): string {
      let resultStr = "[";
      groupMatrix.value.forEach((groupRow) => {
        groupRow.forEach((groupTuple) => {
          if(groupTuple.active) {
            resultStr = resultStr.concat(groupTuple.group.userGroupId + ", ");
          }
        })
      })
      resultStr = resultStr.trim();
      if(resultStr.length > 1) {
        resultStr = resultStr.slice(0, resultStr.length -1); // Remove trailing comma
      }
      return resultStr.concat("]")
    }

    function setSpoof() {
      const spoofStr = generateGroupListJSON();
      localStorage.setItem("groupSpoofingActive", "true");
      localStorage.setItem("spoofedUserGroups", spoofStr);
      window.location.reload();
    }

    function emitCancel() {
      context.emit("cancel");
    }

    return {
      allGroups,
      groupMatrix,
      setSpoof,
      emitCancel
    }
  }
})
