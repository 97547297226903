import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sidebar-inner noselect" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item-head"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SidebarNavProposal = _resolveComponent("SidebarNavProposal")!
  const _component_proposal_page_left_side_nav = _resolveComponent("proposal-page-left-side-nav")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$route.name.includes('New'))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "component-container",
          style: _normalizeStyle(_ctx.sidebar.sideBarVisible ? _ctx.containerOpenCss: null)
        }, [
          _createElementVNode("nav", {
            class: "sidebar",
            style: _normalizeStyle(_ctx.sidebar.sideBarVisible ? _ctx.sideBarOpenCss: _ctx.sideBarCloseCss)
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarManager.menuItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [
                  (item.type===_ctx.SideBarMenuItem.TYPE.HEADER && item.show)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.title), 1))
                    : _createCommentVNode("", true),
                  (item.type===_ctx.SideBarMenuItem.TYPE.BUTTON && (item.show || _ctx.hasAccess(item)))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({'btn-nav-parent': true, 'btn-nav-parent-active': _ctx.$route.name === item.routeName}),
                        onClick: ($event: any) => (_ctx.navClick(item.routeName))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: item.icon
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(item.title), 1)
                      ], 10, _hoisted_3))
                    : _createCommentVNode("", true),
                  (item.type===_ctx.SideBarMenuItem.TYPE.PARENT && item.show)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", {
                          class: _normalizeClass({'btn-nav-parent': true, 'btn-nav-parent-active': _ctx.$route.meta.parent === item.title.toLowerCase()}),
                          onClick: ($event: any) => (_ctx.toggleAccordion(item.id))
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: item.icon
                          }, null, 8, ["icon"]),
                          _createElementVNode("span", null, _toDisplayString(item.title), 1),
                          _createVNode(_component_font_awesome_icon, {
                            icon: item.icon,
                            class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(item.id), 'rotate-chevron-0': !_ctx.state.accordion.includes(item.id), 'chevron-icon': true})
                          }, null, 8, ["icon", "class"])
                        ], 10, _hoisted_5),
                        _createElementVNode("div", {
                          class: _normalizeClass({'btn-accordion-open': true, 'accordion-content-open': _ctx.state.accordion.includes(item.id), 'accordion-content-closed': !_ctx.state.accordion.includes(item.id)})
                        }, [
                          _createVNode(_component_SidebarNavProposal, { item: item }, null, 8, ["item"])
                        ], 2)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ], 4),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.sidebar.sideBarVisible === true)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideSideBar && _ctx.hideSideBar(...args))),
                  class: "sidebar-underlay",
                  style: _normalizeStyle(_ctx.mobile ? null: {display: 'none'})
                }, null, 4))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content")
          ])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.$route.name.includes('New'))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "component-container",
          style: _normalizeStyle(_ctx.containerOpenCss)
        }, [
          _createVNode(_component_proposal_page_left_side_nav, {
            navBarStyle: _ctx.sidebar.sideBarVisible ? _ctx.sideBarOpenCss: _ctx.sideBarCloseCss
          }, null, 8, ["navBarStyle"]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.sidebar.sideBarVisible === true)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideSideBar && _ctx.hideSideBar(...args))),
                  class: "sidebar-underlay",
                  style: _normalizeStyle(_ctx.mobile ? null: {display: 'none'})
                }, null, 4))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content")
          ])
        ], 4))
      : _createCommentVNode("", true)
  ], 64))
}