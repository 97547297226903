import {reactive} from "vue";
import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import ProfileData from "@/domain/profile/ProfileData";
import SaveableSearch from "@/dto/SaveableSearchDTO";
import SaveableUITablePreferences from "@/dto/SaveableUITablePreferencesDTO";
import Group from "@/domain/profile/Group";

export type ProfileStoreState = {
    loggedIn: boolean;
    username: string;
    firstName: string;
    lastName: string;
    groups: Array<Group>;
    reauthRequired: boolean;
    cBomViews: Array<string>;
    ownSearches: Array<SaveableSearch>;
    sharedSearches: Array<SaveableSearch>;
    ownTblColumnSetups: Array<SaveableUITablePreferences>;
    restrictedUser: boolean;
    userID: number | undefined;
    allowsSpoofing: boolean;
}

@store
export default class ProfileStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    // state
    private state = reactive<ProfileStoreState>({
        loggedIn: false,
        username: "",
        firstName: "",
        lastName: "",
        groups: [],
        reauthRequired: false,
        cBomViews: new Array<string>(),
        ownSearches: [],
        sharedSearches: [],
        ownTblColumnSetups: [],
        restrictedUser: false,
        userID: undefined,
        allowsSpoofing: false
    });

    // getters
    get loggedIn(): boolean {
        return this.state.loggedIn;
    }

    get username(): string {
        return this.state.username;
    }

    get firstName(): string {
        return this.state.firstName;
    }

    get lastName(): string {
        return this.state.lastName;
    }

    get groups(): Group[] {
        return this.state.groups;
    }

    get reauthRequired(): boolean {
        return this.state.reauthRequired;
    }

    // get userID(): number {
    //     return 0;
    // }

    get cBomViews(): Array<string> {
        return this.state.cBomViews;
    }

    get ownSearches(): Array<SaveableSearch> {
        return this.state.ownSearches;
    }

    get sharedSearches(): Array<SaveableSearch> {
        return this.state.sharedSearches;
    }

    get ownTblColumnSetups(): Array<SaveableUITablePreferences> {
        return this.state.ownTblColumnSetups;
    }

    set ownTblColumnSetups(value: Array<SaveableUITablePreferences>) {
        this.state.ownTblColumnSetups = value;
    }

    get restrictedUser(): boolean {
        return this.state.restrictedUser;
    }

    set userID(value: number) {
        this.state.userID = value;
    }

    get userID(): number {
        if(this.state.userID) {
            return this.state.userID;
        } else {
            return -1;
        }
    }

    get allowsSpoofing(): boolean {
        return this.state.allowsSpoofing;
    }

    public handleAuthFail() {
        if (this.state.loggedIn) {
            this.state.reauthRequired = true;
            //AuthStore.getInstance().signOut('reauthRequired');
        }
    }

    public addCBomView(view: string) {
        if (this.state.cBomViews.includes(view)) {
            let index = this.state.cBomViews.indexOf(view);
            this.state.cBomViews.splice(index,1);
        } else {
            this.state.cBomViews.push(view);
        }
        this.updateSessionCBomViews();
    }

    public updateSessionCBomViews() {
        let currCbomViews = "";
        this.state.cBomViews.forEach(view => currCbomViews += view + ",");
        localStorage.setItem('cBomViews', currCbomViews);
    }

    public setInitialCBomViews() {
        let initialCBomViews = localStorage.getItem('cBomViews');
        let routeViews = initialCBomViews?.split(",");
        if (routeViews && routeViews.length > 0) {
            for (const view of routeViews) {
                if (view) {
                    this.state.cBomViews.push(view);
                }
            }
        }
    }

    public reauthSuccess() {
        this.state.reauthRequired = false;
    }

    public setSearches(ownSearches: Array<SaveableSearch>, sharedSearches: Array<SaveableSearch>): void {
        this.state.ownSearches = ownSearches;
        this.state.sharedSearches = sharedSearches;
    }

    //actions
    public initProfile(profile: ProfileData) {
        this.state.loggedIn = true;
        this.state.groups = profile.groups;
        this.state.username = profile.username;
        this.state.firstName = profile.firstName;
        this.state.lastName = profile.lastName;

        this.state.restrictedUser = profile.groups.filter(g=> g.hasRestrictedAccess()).length === 0;
        this.setInitialCBomViews();
    }

    public clearProfile() {
        this.state.loggedIn = false;
        this.state.groups = [];
        this.state.username = "";
        this.state.firstName = "";
        this.state.lastName = ""
        this.state.reauthRequired = false;
        this.state.cBomViews = [];
        this.state.allowsSpoofing = false;
    }

    public setSpoofingAllowed(value: boolean) {
        this.state.allowsSpoofing = value;
    }
}
