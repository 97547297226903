import EmailRequestDTO from "@/dto/EmailRequestDTO";
import {BASE_API_URL} from "@/config/env";
import {
    GetGeneralListSuccessResult, GetSingleItemResult
} from "@/service/api/types/ApiResponseTypes";
import UserTag from "@/dto/UserTag";
import ApiService from "@/service/api/ApiService";

export default class NotificationApiService extends ApiService{

    public async sendNotification(emailRequestDTO: EmailRequestDTO): Promise<GetSingleItemResult<string>> {
        const data = JSON.stringify(emailRequestDTO);
        return this.handlePostRequestReturningItem(
            `${BASE_API_URL}/email/notifyUsers`,
            data,
            {'Content-Type': 'application/json'},
            'send notification ' + new Date().toLocaleTimeString()
        )
    }

    public async getTaggableUsers(): Promise<GetGeneralListSuccessResult<UserTag>> {
        return this.handleGetListRequest(
            `${BASE_API_URL}/email/getTaggableUsers`,
            'get taggable users ' + new Date().toLocaleTimeString(),
            {'Content-Type': 'application/json'}
        )
    }

    public async getTaggableUsersByGroup(groupId: number): Promise<GetGeneralListSuccessResult<UserTag>> {
        const data = JSON.stringify(groupId);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/email/getUserTagsByGroup`,
            data,
            {'Content-Type': 'application/json'},
            'get taggable users by group ' + new Date().toLocaleTimeString()
        )
    }

    public async getUserTags(tag: string): Promise<GetGeneralListSuccessResult<UserTag>> {
        const data = JSON.stringify(tag);
        return this.handlePostRequestReturningArray(
            `${BASE_API_URL}/email/getUserTag`,
            data,
            {'Content-Type': 'application/json'},
            'get users from tag ' + new Date().toLocaleTimeString()
        )
    }
}