import axios, {AxiosResponse} from 'axios';
import {BASE_API_URL, USER_SERVICE_TOKEN_URL, USER_SERVICE_URL} from "@/config/env";
import ProfileStore from "@/store/ProfileStore";
import rootStore from "@/store/RootStore";
import {
    GeneralStatusResponse,
    GetGeneralListSuccessResult,
    GetProfileResult,
} from "@/service/api/types/ApiResponseTypes";
import {APPLICATION_NAME, APPLICATION_SECRET} from "@/config/properties";
import AuthService from "@/service/AuthService";
import Group from "@/domain/profile/Group";
import ApiService from "@/service/api/ApiService";

export default class AuthApiService extends ApiService{

    private profileStore: ProfileStore;

    constructor() {
        super();
        this.profileStore = rootStore.getInstance().profileStore;
    }
    public async authenticate(username: string, password: string): Promise<GeneralStatusResponse> {
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);

        const response: AxiosResponse = await axios({
            method: 'post',
            url: USER_SERVICE_TOKEN_URL,
            data: formData,
            auth: {
                username: APPLICATION_NAME,
                password: APPLICATION_SECRET,
            },
        })

        // User profile not returned from user service
        if (!response.data) return {success: false, message: response.data}

        try {
            const authService = new AuthService();

            return await authService.authSuccess(response.data.access_token)

        } catch {
            return {success: false, message: response.data}
        }
    }
    //depreciated
    // Get profile from userservice
    public async getProfile(): Promise<GetProfileResult> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${USER_SERVICE_URL}/profile/${APPLICATION_NAME}`,
        })
        if (!response.data) return { success: false, message: response.data, profile: {firstName: "", lastName: "", username: "", groups: []} }

        return {success: true, profile: { firstName: response.data.firstName, lastName: response.data.lastName, username: response.data.username, groups: response.data.groups} }

    }

    public async getGroupsByUserID(userID: number): Promise<GetGeneralListSuccessResult<Group>> {
        const result = await this.handleGetListRequest<Group>(
            `${BASE_API_URL}/users/get-groups/${userID}`,
            `Get groups by user ID ${new Date().toLocaleTimeString()}`
        )

        const groupArr: Array<Group> = [];
        if (result.items) {
            result.items.forEach((groupLiteral) => {
                groupArr.push(new Group(groupLiteral))
            }); // Create objects from JSON literals
        }
        result.items = groupArr;
        return result;
    }
}
