
import {
  defineComponent, onMounted, PropType, reactive, watch, onBeforeUnmount, computed,
} from 'vue';
import BCol from "@/components/bootstrap-library/BCol.vue";
import BFormInput from "@/components/bootstrap-library/BFormInput.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import BSpinner from "@/components/bootstrap-library/BSpinner.vue";
import useScreenDetector from "@/composable/useScreenDetector";


type State = {
  search: string;
  results: Array<unknown>;
  isOpen: boolean;
  isSet: boolean;
  hasData: boolean;
  loading: boolean;
  direction: 'up' | 'down';
};

export default defineComponent({
  name: 'dropdown-autocomplete-base',
  components: {
    BCol,
    BFormInput,
    BButton,
    BSpinner,
  },
  props: {
    isNewUi: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    searchBy: { type: String, required: true },
    searchFn: Function as PropType<(search: string) => Promise<Array<any>>>,
    searchDebounceTimeout: { type: Number, default: 500 },
    onBeforeClear: Function as PropType<() => Promise<boolean>>,
    modelValue: String,
    placeholder: {
      type: String,
      default: () => `Type to search...`,
    },
    disabled: Boolean,
    label: {
      type: String,
      default: undefined,
    },
    cols: {
      type: String,
      default: undefined,
    },
    lg: {
      type: String,
      default: undefined,
    },
    md: {
      type: String,
      default: undefined,
    },
    sm: {
      type: String,
      default: undefined,
    },
    xs: {
      type: String,
      default: undefined,
    },
    loading: { type: Boolean, default: false },
    emptyText: { type: String, default: () => 'No results' },
    error: {
      type: String,
      default: undefined,
    },
    focusOnLoad: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: false },
    highlightedItems: { type: Array as PropType<Array<string>>, default: () => [] },
    lockInputOnSelect: { type: Boolean, default: true },
    textDisplayMap: { type: Map, default: () => new Map<string,string>() },
    freeForm: { type: Boolean, default: false }
  },
  emits: ['onSelect', 'onClear'],
  setup(props, context) {
    const state = reactive<State>({
      search: '',
      results: [],
      isOpen: false,
      isSet: false,
      hasData: false,
      loading: props.loading,
      direction: 'down',
    });
    const isSet = computed((): boolean => !!props.modelValue);
    const inputDisabled = computed((): boolean => props.disabled || isSet.value || !state.hasData);
    let searchTimeout: number | undefined;

    onBeforeUnmount(() => {
      clearTimeout(searchTimeout);
    });

    const { element: autocompleteBaseRef, getPosition } = useScreenDetector();

    function setInitialValue() {
      if (!props.modelValue || !state.hasData) {
        return;
      }
      if (props.searchFn) {
        state.search = props.modelValue;
      } else {
        //allow values not provided in dataset to be selectable
        if (props.freeForm) {
          state.search = props.modelValue;
        } else {
          const index = props.data.findIndex((data) => data === props.modelValue);
          if (index > -1) {
            state.search = props.modelValue;
          }
        }
      }
    }

    onMounted(() => {
      state.isOpen = false;
      state.results = props.searchFn ? [] : props.data;
      if (props.searchFn || props.data.length) {
        state.hasData = true;
      }
      setInitialValue();
    });

    watch(
        () => props.disabled,
        (newVal: boolean, oldVal?: boolean) => {
          if (!oldVal && newVal && props.lockInputOnSelect) {
            state.search = props.modelValue ? props.modelValue : '';
          }
        },
    );

    watch(
        () => props.loading,
        (newVal: boolean, oldVal?: boolean) => {
          if (!newVal && oldVal && props.lockInputOnSelect) {
            state.loading = newVal;
            state.search = props.modelValue ? props.modelValue : '';
            if (props.searchFn) {
              state.hasData = true;
            } else {
              state.hasData = props.data.length > 0;
              state.results = props.data;
            }
            setInitialValue();
          }
        },
        { immediate: true },
    );

    async function toggleOpen() {
      state.direction = getPosition() === 'top' ? 'down' : 'up';
      state.isOpen = !state.isOpen;
    }

    async function doSearch() {
      if (props.searchFn) {
        state.loading = true;
        state.results = await props.searchFn(state.search.trim());
        state.loading = false;
      } else {
        state.results = props.data;
      }
    }

    async function onChange(val: string) {
      state.search = val;
      if (state.search.trim().length > 0) {
        if (props.searchFn) {
          clearTimeout(searchTimeout);
          // debounce the search to minimize requests to the server
          searchTimeout = setTimeout(doSearch, props.searchDebounceTimeout);
        } else {
          const lowerSearch = state.search.toLowerCase();
          state.results = props.data.filter((d: any) => d?.toString().toLowerCase().includes(lowerSearch));
        }
        setTimeout(async function() {
          if (!state.isOpen) {
            await toggleOpen();
          }
        }, (props.searchDebounceTimeout));
      } else {
        state.results = props.searchFn ? [] : props.data;
        if (props.closeOnSelect) state.isOpen = false;
      }
    }

    async function clear() {
      if (!props.disabled) {
        if (isSet.value && props.onBeforeClear) {
          const response = await props.onBeforeClear();
          if (response) {
            context.emit('onSelect', null);
            state.search = '';
          }
        } else {
          context.emit('onSelect', null);
          state.search = '';
        }
        state.results = props.searchFn ? [] : props.data;
        context.emit('onClear');
      }
    }

    function setResult(result: any) {
      context.emit('onSelect', result);
      if (props.lockInputOnSelect) {
        state.search = result;
      }
      if (props.closeOnSelect) state.isOpen = false;
    }

    function handleClickOutside() {
      state.isOpen = false;
    }

    function highlight(value: string): boolean {
      return !!props.highlightedItems?.includes(value);
    }

    function getDisplayValue(value: string): string {
        if (props.textDisplayMap) {
          let groupText = props.textDisplayMap.get(value);
          if (groupText) {
            return value + " | " + groupText;
          }
        }
        return value;
    }

    function setSelection() {
      if (props.freeForm) {
        setResult(state.search);
      }
    }

    return {
      state,
      onChange,
      setResult,
      toggleOpen,
      clear,
      handleClickOutside,
      inputDisabled,
      isSet,
      highlight,
      autocompleteBaseRef,
      getDisplayValue,
      setSelection
    };
  },
});
