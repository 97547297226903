import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07bf378d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar__section-title" }
const _hoisted_2 = { class: "sidebar__team" }
const _hoisted_3 = { class: "sidebar__team-member" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "sidebar__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.teamMember.role), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.teamMember.imageURL,
          alt: _ctx.teamMember.name,
          class: "sidebar__avatar"
        }, null, 8, _hoisted_4),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.teamMember.name), 1)
      ])
    ])
  ], 64))
}