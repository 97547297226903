
import {
    computed, defineComponent, nextTick, onActivated, onMounted, onUpdated, PropType, reactive, ref,
} from 'vue';
import useNumberIncrementer from './composables/useNumberIncrementer';
import Popover from '@/components/bootstrap-library/Popover.vue';

type InputEvaluationResult = {
    returnedValue: number | string,
    attemptedValue: number | string,
}

type State = {
    componentKey: number
}

type InputType =
    'text'
    | 'number'
    | 'email'
    | 'password'
    | 'search'
    | 'url'
    | 'tel'
    | 'date'
    | 'time'
    | 'range'
    | 'color';

interface NumberOptions {
    allowsDecimal?: boolean
    allowsNegative?: boolean
}

export default defineComponent({
    name: 'b-form-input',
    components: {
        Popover,
    },
    emits: ['update:modelValue', 'change', 'input', 'keypress', 'focus', 'blur'],
    props: {
        modelValue: [String, Number],
        label: String,
        placeholder: String,
        autocomplete: { type: String },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: { type: Boolean, default: false },
        max: Number,
        min: Number,
        maxlength: Number,
        step: { type: [ Number , String ], default: 'any' },
        type: {
            type: String as PropType<InputType>,
            default: 'text',
        },
        hideStepper: { type: Boolean, default: true },
        trim: {
            type: Boolean,
            default: false,
        },
        number: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        error: String,
        appendGroupText: String,
        appendPrefixGroup: String,
        numberOptions: Object as PropType<NumberOptions>,
        moreInfo: String,
        preventTyping: Boolean,
    },
    computed: {
        getValidity() {
          return () => {
            if (this.required) {
              if (typeof(this.modelValue) == 'string' ? this.modelValue.trim() : Number.isInteger(this.modelValue)) {
                return "font-size: 1em;";
              } else {
                return "font-size: 1em; border: 1px solid red;";
              }
            } else {
              return "font-size: 1em;";
            };
          }
        }
    },
    setup(props, context) {
        const input = ref<HTMLElement>();
        const state = reactive<State>({
            componentKey: 0,
        });

        // lifecycle events
        const handleAutofocus = () => {
            nextTick(() => {
                if (props.autofocus) {
                    input.value?.focus();
                }
            });
        };

        onMounted(handleAutofocus);
        onActivated(handleAutofocus);
        onUpdated(handleAutofocus);

        function onInput(evt: Event) {
            const { value } = evt.target as HTMLTextAreaElement;
            context.emit('input', value);
        }

        function onKeyPress(event: KeyboardEvent) {
            if (props.type === 'number' && !isValidKeyForNumber(event.key, props.numberOptions)) {
                event.preventDefault();
            } else {
                context.emit('keypress', event);
            }
        }

        function onKeyDown(event: KeyboardEvent) {
            if (props.preventTyping) {
                event.preventDefault();
            }
        }

        function onChange(evt: Event) {
            const inputElValue = (evt.target as HTMLTextAreaElement).value;
            let formattedValue: number | string = inputElValue;

            if (props.type === 'number') {
                formattedValue = convertToNumber(inputElValue);
            }

            const { attemptedValue, returnedValue } = evaluateMaxMin(formattedValue);
            const valueToEmit = returnedValue;

            context.emit('update:modelValue', valueToEmit);
            context.emit('change', valueToEmit, attemptedValue);

            if (attemptedValue !== returnedValue) {
                state.componentKey++;
            }
        }

        function onFocus() {
            context.emit('focus');
        }

        function onBlur() {
            context.emit('blur');
        }

        const isDisabled = computed(() => props.disabled);

        const showStepper = computed((): boolean => !(props.hideStepper || props.type !== 'number'));

        function handleStep(value: number) {
            if (props.type === 'number') {
                const stepFactor = value * (typeof props.step == 'number' ? props.step : 1);
                const modelValue: number = convertToNumber(props.modelValue);

                const { returnedValue } = evaluateMaxMin(modelValue + stepFactor);

                context.emit('update:modelValue', returnedValue);
                context.emit('input', returnedValue);
                context.emit('change', returnedValue);
            }
        }

        function isValidKeyForNumber(keyboardKey: string, numberOptions?: NumberOptions): boolean {
            if (numberOptions?.allowsDecimal && keyboardKey === '.') {
                return true;
            }

            if (numberOptions?.allowsNegative && keyboardKey === '-') {
                return true;
            }

            return !!(keyboardKey.match(/^\d$/));
        }

        const { stop, numberChange } = useNumberIncrementer({ changeCallback: handleStep });

        function convertToNumber(value: string | number | undefined): number {
            return !value ? 0 : typeof value === 'number' ? value : parseFloat(value);
        }

        function evaluateMaxMin(value: number | string): InputEvaluationResult {
            const result: InputEvaluationResult = { attemptedValue: value, returnedValue: value };
            if (typeof value === 'number') {
                if (props.min && value < props.min) {
                    result.returnedValue = props.min;
                }
                if (props.max && value > props.max) {
                    result.returnedValue = props.max;
                }
                return result;
            }
            return result;
        }

        const hasAppendGroupSlot = computed((): boolean => !!context.slots.appendGroupText);
        const hasPrefixGroupSlot = computed((): boolean => !!context.slots.appendPrefixGroup);
        const hasAppendGroup = computed((): boolean => !!props.appendGroupText || hasAppendGroupSlot.value);
        const hasPrefixGroup = computed((): boolean => !!props.appendPrefixGroup || hasAppendGroupSlot.value);

        return {
            input,
            onInput,
            onKeyPress,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            isDisabled,
            showStepper,
            stop,
            numberChange,
            state,
            hasAppendGroup,
            hasAppendGroupSlot,
            hasPrefixGroup,
            hasPrefixGroupSlot
        };
    },
});
