
import { defineComponent } from 'vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import DropdownAutocompleteMultiSelect from "@/components/DropdownAutocompleteMultiSelect.vue";

type Option = {
  text: string;
  value: string | number | null; // casing is manipulated so mismatched casings match
  disabled?: boolean;
}

export default defineComponent({
  name: 'form-multi-select',
  components: {
    DropdownAutocompleteMultiSelect, BButton, BBadge, BCol,
  },
  extends: DropdownAutocompleteMultiSelect,
  props: {
    textDisplayMap: { type: Map, default: () => new Map<string,string>() },
    isNewUi: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup(props, context) {

    function clearSelections() {
      context.emit('update:modelValue', []);
    }

    function clearSelection(val: Object) {
      // @ts-ignore
      const copy = [...props.modelValue];
      const newCopy = copy.filter((d) => d !== val);
      context.emit('update:modelValue', newCopy);
    }

    function onSelect(val: Object) {
      context.emit('update:modelValue', val);
    }

    function getDisplayValue(value: string): string {
      if (props.textDisplayMap) {
        let groupText = props.textDisplayMap.get(value);
        if (groupText) {
          return value + " | " + groupText;
        }
      }
      return value;
    }

    return {
      onSelect,
      clearSelections,
      clearSelection,
      getDisplayValue
    };
  },
});
