import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a27d8842"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"20%","padding-left":"10px"}
}
const _hoisted_2 = { class: "log-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"padding-left":"5px"} }
const _hoisted_6 = {
  key: 0,
  class: "log-detail"
}
const _hoisted_7 = { style: {"padding-bottom":"10px"} }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "sidebarlog",
    style: _normalizeStyle(_ctx.sidebar.sideBarVisible ? _ctx.sideBarOpenCss: _ctx.sideBarCloseCss)
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logClick && _ctx.logClick(...args))),
      style: {"position":"absolute","display":"grid","top":"50%","margin-right":"5px"}
    }, [
      _createVNode(_component_font_awesome_icon, {
        style: {"grid-column":"1"},
        icon: _ctx.sidebar.sideBarVisible ?  'fa-solid fa-chevron-right' : 'fa-solid fa-chevron-left'
      }, null, 8, ["icon"]),
      (_ctx.errors.length > 0)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "log-alert",
            icon: 'fas fa-exclamation-circle',
            style: {"grid-column":"2","border-radius":"50%","background-color":"#353535","padding":"2px"}
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.sidebar.sideBarVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_b_button, {
            class: "clear-log",
            onClick: _ctx.clearLog,
            disabled: !_ctx.errors
          }, {
            default: _withCtx(() => [
              _createTextVNode("Clear Log")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
              return (_openBlock(), _createElementBlock("div", null, [
                (error.header)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "log-item-header-err",
                        onClick: ($event: any) => (_ctx.toggleView(error))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: error.expanded ?  'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(error.header), 1)
                      ], 8, _hoisted_4),
                      (error.expanded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("span", null, _toDisplayString(error.body), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(error), 1)
                    ]))
              ]))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}