import Vue from 'vue'
//import OktaVue from '@okta/okta-vue';
import App from './App.vue'
import {createApp} from 'vue';
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
    faCheck,
    faMagnifyingGlass,
    faX,
    faPencil,
    faBook,
    faCalendar,
    faDolly,
    faTableList,
    faReceipt,
    faMoneyBill,
    faTrash,
    faCircleInfo,
    faPlus,
    faMinus,
    faEye,
    faCalculator,
    faList,
    faLayerGroup,
    faTable,
    faClipboardCheck,
    faPaste,
    faDoorOpen,
    faFileLines,
    faFilter,
    faScrewdriverWrench,
    faInfo,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faChevronUp,
    faChevronDown,
    faAngleRight,
    faAngleLeft,
    faAnglesRight,
    faAnglesLeft,
    faXmark,
    faTableColumns,
    faUser,
    faEyeSlash,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faEllipsisV,
    faEllipsisH,
    faPrint,
    faClock,
    faGaugeHigh,
    faExpand
} from "@fortawesome/free-solid-svg-icons";

import '@vuepic/vue-datepicker/dist/main.css'; // Styles for 3rd party datepicker
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'; // Styles from bootstrap dependency
import '@/components/bootstrap-library/stylesheets/main.scss'; // Styles from our bootstrap component library
import './stylesheets/main.scss'; // Momentum Management stylesheets


import StartupService from "@/service/StartupService";
import {APPLICATION_NAME} from "@/config/properties";
import BCol from "@/components/bootstrap-library/BCol.vue";
import BTable from "@/components/bootstrap-library/table/BTable/BTable.vue";
import BFormInput from "@/components/bootstrap-library/BFormInput.vue";
import BDropdownItem from "@/components/bootstrap-library/BDropdownItem.vue";
import BBanner from "@/components/bootstrap-library/BBanner.vue";
import BDropdown from "@/components/bootstrap-library/BDropdown.vue";
import {ClickOutsideDirective} from "@/components/bootstrap-library/directives/clickoutside";
import BButtonGroup from "@/components/bootstrap-library/BButtonGroup.vue";
import BNavbarNav from "@/components/bootstrap-library/BNavbarNav.vue";
import {TestDirective} from "@/components/bootstrap-library/directives/test";
import BFormCheckbox from "@/components/bootstrap-library/BFormCheckbox.vue";
import BModal from "@/components/bootstrap-library/BModal.vue";
import BSkeleton from "@/components/bootstrap-library/BSkeleton.vue";
import BNavbarBrand from "@/components/bootstrap-library/BNavbarBrand.vue";
import BPagination from "@/components/bootstrap-library/table/BTable/BPagination.vue";
import BForm from "@/components/bootstrap-library/BForm.vue";
import BFormDatepicker from "@/components/bootstrap-library/BFormDatepicker.vue";
import BRow from "@/components/bootstrap-library/BRow.vue";
import BFormTextarea from "@/components/bootstrap-library/BFormTextarea.vue";
import BFormSelect from "@/components/bootstrap-library/BFormSelect.vue";
import BBadge from "@/components/bootstrap-library/BBadge.vue";
import BNavbar from "@/components/bootstrap-library/BNavbar.vue";
import BAdvancedTable from "@/components/bootstrap-library/table/BAdvancedTable.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import {PopoverDirective} from "@/components/bootstrap-library/directives/popover";
import BSpinner from "@/components/bootstrap-library/BSpinner.vue";
import BFormRadio from "@/components/bootstrap-library/BFormRadio.vue";
import BFormRadioGroup from "@/components/bootstrap-library/BFormRadioGroup.vue";
import BFormGroup from "@/components/bootstrap-library/BFormGroup.vue";
import BCollapse from "@/components/bootstrap-library/BCollapse.vue";

import BCard from "@/components/bootstrap-library/BCard.vue"
import BCardBody from "@/components/bootstrap-library/BCardBody.vue"
import BCardHeader from "@/components/bootstrap-library/BCardHeader.vue";
import BCardFooter from "@/components/bootstrap-library/BCardFooter.vue";
import BCardImg from "@/components/bootstrap-library/BCardImg.vue";
import BCardHeadFoot from "@/components/bootstrap-library/BCardHeadFoot.vue";
import BCardTitle from "@/components/bootstrap-library/BCardTitle.vue";
import BCardSubtitle from "@/components/bootstrap-library/BCardSubtitle.vue";
import BFormSelectOption from "@/components/bootstrap-library/BFormSelectOption.vue";
import {OktaAuth, OktaAuthOptions} from "@okta/okta-auth-js";
import oidcConfig from "@/config/okta/authConfig";

library.add(faCheck, faMagnifyingGlass, faX, faPencil, faBook, faCalendar, faDolly, faTableList, faReceipt, faMoneyBill, faTrash,
    faCircleInfo, faPlus, faMinus, faEye, faCalculator, faList, faLayerGroup, faTable, faClipboardCheck, faPaste, faDoorOpen, faFileLines,
    faFilter, faScrewdriverWrench, faInfo, faArrowLeft, faChevronUp, faArrowUp, faArrowDown,
    faChevronDown, faAngleRight, faAngleLeft, faAnglesRight, faAnglesLeft, faXmark, faTableColumns, faUser, faEyeSlash, faChevronLeft, faChevronRight,
    faExclamationCircle, faEllipsisV, faEllipsisH, faPrint, faClock, faGaugeHigh, faExpand);

/*
oidcConfig().then(async (config: OktaAuthOptions) => {
    if (!config.clientId) {
        alert('Unable to find client id for subdomain');
        throw new Error('Unable to find client id for subdomain');
    }
*/
    //const oktaAuth = new OktaAuth(config);
    document.title = APPLICATION_NAME;

// get token from storage, get profile, navigate to initial route
    const startupService = new StartupService();
    const url = window.location.href;
    startupService.startApp(url);
   // const authStore = AuthStore.getInstance();

    const app = createApp(App);
    app.use(router);

    app.component('BRow', BRow);
    app.component('BCol', BCol);
    app.component('BButton', BButton);
    app.component('BButtonGroup', BButtonGroup);
    app.component('BFormInput', BFormInput);
    app.component('BForm', BForm);
    app.component('BFormCheckbox', BFormCheckbox);
    app.component('BFormTextarea', BFormTextarea);
    app.component('BFormSelect', BFormSelect);
    app.component('BFormSelectOption', BFormSelectOption)
    app.component('BFormDatepicker', BFormDatepicker)

    app.component('BFormRadio', BFormRadio);
    app.component('BFormRadioGroup', BFormRadioGroup);
    app.component('BModal', BModal);
    app.component('BSkeleton', BSkeleton);
    app.component('BCollapse', BCollapse);
    app.component('BCard', BCard)
        .component('BCardFooter', BCardFooter)
        .component('BCardHeader', BCardHeader)
        .component('BCardBody', BCardBody)
        .component('BCardImg', BCardImg)
        .component('BCardHeadFoot', BCardHeadFoot)
        .component('BCardTitle', BCardTitle)
        .component('BCardSubtitle', BCardSubtitle);

    app.component('BFormGroup', BFormGroup);
    app.component('BNavbar', BNavbar);
    app.component('BNavbarNav', BNavbarNav);
    app.component('BNavbarBrand', BNavbarBrand);

    app.component('BDropdown', BDropdown);
    app.component('BDropdownItem', BDropdownItem);
// app.component('BAccordion', BAccordion)
//     .component('BAccordionItem', BAccordionItem);

    app.component('BBadge', BBadge);
    app.component('BSpinner', BSpinner);
    app.component('BBanner', BBanner);

    app.component('BTable', BTable);
    app.component('BAdvancedTable', BAdvancedTable);
    app.component('BPagination', BPagination);

    app.directive('click-outside', ClickOutsideDirective);
    app.directive('test', TestDirective);
    app.directive('popover', PopoverDirective);

    app.component('font-awesome-icon', FontAwesomeIcon);

    //app.use(router);
    //app.use(OktaVue, { oktaAuth });
    app.mount('#vue-app');
    //await authStore.initAuth(oktaAuth);
// router.isReady().then(()=>{app.mount('#vue-app');})

//
// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#vue-app')
//})
