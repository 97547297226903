import {reactive} from "vue";
import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import Proposal from "@/domain/proposal/Proposal";
import ProductBom from "@/domain/proposal/ProductBom";
import PdmData from "@/domain/proposal/PdmData";
import ProposalPriceListEntry from "@/domain/proposal/ProposalPriceListEntry";
import PriceListHeader from "@/domain/proposal/PriceListHeader";
import SideBarMenuItem from "@/views/sidebar/domain/SideBarMenuItem";

export interface RouteInterface {
    id: number,
    title: string,
    icon: string,
    type: string,
    routeName: string,
    show: boolean
}

const ProposalRoutes: Array<RouteInterface> = [{
        id: 11,
        title: 'Proposal',
        icon: 'fa-book',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalHeader',
        show: false
    },
    {
        id: 12,
        title: 'Scheduling',
        icon: 'fa-calendar',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalScheduling',
        show: false
    },
    {
        id: 13,
        title: 'Text Maintenance',
        icon: 'fa-pencil',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalTextMaintenance',
        show: false
    },
    {
        id: 14,
        title: 'Sample',
        icon: 'fa-dolly',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalSample',
        show: false
    }, {
        id: 16,
        title: 'Sub Assemblies',
        icon: 'fa-table-list',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewSubAssemblies',
        show: false
    },
    {
        id: 17,
        title: 'Component Totals',
        icon: 'fa-receipt',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalComponentTotals',
        show: false
    },
    {
        id: 18,
        title: 'COST',
        icon: 'fa-money-bill',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalCost',
        show: false
    },
    {
        id: 19,
        title: 'Reports',
        icon: 'fa-table-list',
        type: SideBarMenuItem.TYPE.BUTTON,
        routeName: 'NewProposalReports',
        show: false
    }];

type State = {
    key: number;
    containerID: string;
    proposal: Proposal;
    initialProposal: Proposal;
    isDirty: boolean;
    pdmData: Array<PdmData>;
    invalidTotalAsmTime: boolean;
    recostAllowed: boolean;
    allowedControls: Array<string>;
    priceList: Array<ProposalPriceListEntry>;
    priceListHeaders: Array<PriceListHeader>;
    routes?: Array<RouteInterface>;
}


@store
export default class ProposalStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.state.initialProposal = new Proposal();
    }

    // private state
    private state = reactive<State>({
        key: 0,
        containerID: '',
        proposal: new Proposal(),
        initialProposal: new Proposal(),
        isDirty: false,
        pdmData: new Array<PdmData>(),
        invalidTotalAsmTime: false,
        recostAllowed: false,
        allowedControls: [],
        priceList: [],
        priceListHeaders: [],
        routes:[]
    });

    // public state

    // Caused issues with change detection in some components. Moved to property of state with getters and setters to fix issue.
//    public proposal = reactive<Proposal>(new Proposal())

    get proposal(): Proposal {
        return this.state.proposal;
    }

    set proposal(prop: Proposal) {
        this.state.proposal = prop;
    }

    get initialProposal(): Proposal {
        return this.state.initialProposal;
    }

    set initialProposal(prop: Proposal) {
        this.state.initialProposal = prop;
    }

    get pdmData() {
        return this.state.pdmData;
    }

    set pdmData(pdmData: Array<PdmData>) {
        this.state.pdmData = pdmData;
    }

    get invalidTotalAsmTime() {
        return this.state.invalidTotalAsmTime;
    }

    set invalidTotalAsmTime(value: boolean) {
        this.state.invalidTotalAsmTime = value;
    }

    get isDirty(): boolean {
        let dirtyComponents = this.proposal.product.productBoms
            .find(pbom => pbom?.edit === true) ? true :
            this.proposal.product.productBoms
                .find(pbom => pbom.componentBom?.edit === true) ? true :
                this.proposal.product.productBoms
                    .find(pbom => pbom.prodRouteItem?.edit === true) ? true :
                    this.proposal.product.productBoms
                        .find(pbom => pbom.prodAmortItem?.edit === true) ? true :
                        this.proposal.product.productBoms
                            .find(pbom => pbom.purchasedItem?.edit === true) ? true : false;
        let dirtyComponentItems = this.proposal.product.productBoms
            .find(pbom => pbom.componentBom?.componentBomItems?.find(bomitems => bomitems?.edit === true)) ? true :
            this.proposal.product.productBoms
                .find(pbom => pbom.componentBom?.componentRouteItems?.find(routeitems => routeitems?.edit === true)) ? true :
                this.proposal.product.productBoms
                    .find(pbom => pbom.componentBom?.componentToolingItems?.find(toolitems => toolitems?.edit === true)) ? true : false;

        return dirtyComponents ? true : dirtyComponentItems ? true : this.state.isDirty;
    }

    public updateDirtyFlag(val: boolean) {
        if (!(val === this.state.isDirty)) {
            this.state.isDirty = val;
        }
    }

    get key(): number {
        return this.state.key;
    }

    private isEmptyString(val: string): boolean {
        if (val) {
            return val.length === 0;
        }
        return true;
    }

    private getEntryDifference(value: [string,any], initialValue: object): boolean {
        const valueMap = value.map(v => v);
        if (initialValue) {
            for (const entry of Object.entries(initialValue)) {
                if (entry[0] === 'productBoms'){
                    //ignore - these are handled independently and saved independently
                } else {
                    const initialValueMap = entry.map(v => v);
                    if (initialValueMap[0] === valueMap[0]) {
                        if (this.isEmptyString(initialValueMap[1]) && this.isEmptyString(valueMap[1])) {
                           // console.log("They are both empty strings");
                        } else {
                            if (initialValueMap[0] === 'statusLogEntries' ||
                                initialValueMap[0] === 'proposalComments' ||
                                initialValueMap[0] === 'costHistory') {
                                //ignore, these are independent/non-user editable
                            } else {
                                let val1 = JSON.stringify(initialValueMap[1]);
                                let val2 = JSON.stringify(valueMap[1]);
                                if (val1 !== val2) {
                                    //console.log("Difference found in " + initialValueMap[0] + ": " + val1 + " different than " + val2 );
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    public isProposalDifferent(proposal: Proposal): boolean {
        for (const entry of Object.entries(proposal)) {
            if (entry[0] === 'product'){
                //special case for product since productBoms saves are independent
                //and would not reflect in initialproposal.product
                for (const pEntry of Object.entries(proposal.product)) {
                    if (this.getEntryDifference(pEntry, this.initialProposal.product)) {
                        return true;
                    }
                }
            } else {
                if (this.getEntryDifference(entry, this.initialProposal)) {
                    return true;
                }
            }
        }
        return false;
    }

    public update(proposal?: Proposal) {
        if (proposal) {
            this.proposal = new Proposal(proposal);
            this.initialProposal = new Proposal(proposal);
            this.state.containerID = '';
            this.state.isDirty = false;
        }
        this.state.key++;
    }

    public getProductBomFromCompNumber(componentNumber: string): ProductBom | null {
        if (this.proposal.product.productBoms) {
            for (const prodBom of this.proposal.product.productBoms) {
                if (prodBom.componentBom && prodBom.componentBom.componentNumber === componentNumber) {
                    return prodBom
                }
            }
        }
        return null
    }

    public getProductBomFromCompID(componentBomID: number): ProductBom | null {
        if (this.proposal.product.productBoms) {
            for (const prodBom of this.proposal.product.productBoms) {
                if (prodBom.componentBom && prodBom.componentBom.componentBomID === componentBomID) {
                    return prodBom
                }
            }
        }
        return null
    }

    public getProductBomByAmortID(amortItemID: number): ProductBom | null {
        if (this.proposal.product.productBoms) {
            for (const prodBom of this.proposal.product.productBoms) {
                if (prodBom.prodAmortItem && prodBom.prodAmortItem.amortItemID === amortItemID)
                    return prodBom
            }
        }
        return null
    }

    public getProductBomByRouteID(routeItemID: number): ProductBom | null {
        if (this.proposal.product.productBoms) {
            for (const prodBom of this.proposal.product.productBoms) {
                if (prodBom.prodRouteItem && prodBom.prodRouteItem.routeItemID === routeItemID)
                    return prodBom
            }
        }
        return null
    }

    public addSample() {
        this.proposal.addSample()
        this.update()
    }

    public removeSample() {
        delete this.proposal.sample
        this.update()
    }
//depreciated per text templates feature 7231
    public updateContainerInfoOnContainerChange() {
        if (this.state.containerID.length < 1) {
            this.state.containerID = this.initialProposal.containerID;
        }
        if (this.state.containerID !== this.proposal.containerID) {
            this.proposal.externalText =
                this.proposal.externalText.replace(this.state.containerID, this.proposal.containerID);
            this.state.containerID = this.proposal.containerID;
        }
    }

    set recostAllowed(value: boolean) {
        this.state.recostAllowed = value;
    }

    get recostAllowed(): boolean {
        return this.state.recostAllowed;
    }

    set allowedControls(value: Array<string>) {
        this.state.allowedControls = value;
    }

    get allowedControls(): Array<string> {
        return this.state.allowedControls;
    }

    set priceList(value: Array<ProposalPriceListEntry>) {
        this.state.priceList = value;
    }

    get priceList(): Array<ProposalPriceListEntry> {
        return this.state.priceList;
    }

    set priceListHeaders(value: Array<PriceListHeader>) {
        this.state.priceListHeaders = value;
    }

    get priceListHeaders() {
        return this.state.priceListHeaders;
    }

    get routes(): Array<RouteInterface> {
        return ProposalRoutes;
    }
}
