import axios, {AxiosRequestHeaders, AxiosResponse, AxiosResponseHeaders, ResponseType} from 'axios';
import APIResponseDTO from "@/dto/APIResponseDTO";
import {
    GetGeneralListSuccessResult, GetGeneralNumberResult,
    GetGeneralSuccessResult,
    GetSingleItemResult
} from "@/service/api/types/ApiResponseTypes";
import {ApiDetailResponseProblem} from "@/service/api/types/ApiDetailResponseProblem";
import LogService from "@/service/LogService";

export default class ApiService {

    private logService = new LogService();

    // Typeguard
    protected static instanceOfAxiosResponse(response: AxiosResponse | ApiDetailResponseProblem): response is AxiosResponse {
        return (response as AxiosResponse).data !== undefined;
    }

    // Typeguard
    protected static instanceOfApiDetailResponseProblem(response: AxiosResponse | ApiDetailResponseProblem): response is ApiDetailResponseProblem {
        return (response as ApiDetailResponseProblem).success !== undefined;
    }

    /**
     * Handles get requests expecting single item results
     * @param targetUrl
     * @param errorHeader - Header to use for issue log if an error is returned.
     * @param headers - Axios headers to send with request. Uses whatever default is configured if none are provided.
     * @param responseType - Response type. Uses whatever default is configured for axios if none is provided.
     */
    public async handleGetRequest<T>(targetUrl: string, errorHeader?: string, headers?: AxiosRequestHeaders, responseType?: ResponseType): Promise<GetSingleItemResult<T>> {
        const response: AxiosResponse | ApiDetailResponseProblem = await axios({
            method: 'get',
            url: targetUrl,
            headers: headers? headers : axios.defaults.headers.get,
            responseType: responseType? responseType : axios.defaults.responseType
        })
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            try {
                const apiResponseDTO = new APIResponseDTO<T>(response.data);
                const item: T = apiResponseDTO.payload;
                return {success: true, item}
            } catch {
                return await this.logUnknownError();
            }
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    public async handleGetRequestReturningSuccess(targetUrl: string, errorHeader?: string): Promise<GetGeneralSuccessResult> {
        const response: AxiosResponse | ApiDetailResponseProblem = await axios({
            method: 'get',
            url: targetUrl,
        })
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            return {success: true};
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    /**
     * Handles get requests expecting array results
     * @param targetUrl
     * @param errorHeader - Header to use for issue log if an error is returned.
     */
    public async handleGetListRequest<T>(targetUrl: string, errorHeader?: string, headers?: AxiosRequestHeaders): Promise<GetGeneralListSuccessResult<T>> {
        const response: AxiosResponse | ApiDetailResponseProblem = await axios({
            method: 'get',
            url: targetUrl,
            headers: headers? headers : axios.defaults.headers.get
        })

        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            try {
                const apiResponseDTO = new APIResponseDTO<Array<T>>(response.data);
                const items: Array<T> = apiResponseDTO.payload;
                return {success: true, items}
            } catch {
                return await this.logUnknownError();
            }
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    /**
     * Handles post requests expecting either single item or array results back
     * @param targetUrl
     * @param data - Data for request formatted as a string
     * @param headers - Array of headers for request
     * @param errorHeader - Header to use for issue log if an error is returned.
     */
    public async handlePostRequestReturningItem<T>(targetUrl: string, data: string, headers: AxiosRequestHeaders, errorHeader?: string): Promise<GetSingleItemResult<T>> {
        const response: AxiosResponse | ApiDetailResponseProblem = await axios({
            method: 'post',
            url: targetUrl,
            data,
            headers: headers
        })
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            try {
                const apiResponseDTO = new APIResponseDTO<T>(response.data);
                const item: T = apiResponseDTO.payload;
                return {success: true, item}
            } catch {
                return await this.logUnknownError();
            }
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    /**
     * Handles post requests expecting either single item or array results back
     * @param targetUrl
     * @param data - Data for request formatted as a string
     * @param headers - Array of headers for request
     * @param errorHeader - Header to use for issue log if an error is returned.
     */
    public async handlePostRequestReturningArray<T>(targetUrl: string, data: string, headers: AxiosRequestHeaders, errorHeader?: string): Promise<GetGeneralListSuccessResult<T>> {
        const response: AxiosResponse | ApiDetailResponseProblem = await axios({
            method: 'post',
            url: targetUrl,
            data,
            headers: headers
        })
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            try {
                const apiResponseDTO = new APIResponseDTO<Array<T>>(response.data);
                const items: Array<T> = apiResponseDTO.payload;
                return {success: true, items}
            } catch {
                return await this.logUnknownError();
            }
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    /**
     * Handles post requests expecting either single item or array results back
     * @param targetUrl
     * @param errorHeader - Header to use for issue log if an error is returned.
     * @param data - Data for request formatted as a string
     * @param headers - Array of headers for request
     */
    public async handlePostRequestReturningSuccess(targetUrl: string, errorHeader?: string, data?: string, headers?: AxiosRequestHeaders): Promise<GetGeneralSuccessResult> {
        let response: AxiosResponse | ApiDetailResponseProblem;
        if(data && headers) {
            response = await axios({
                method: 'post',
                url: targetUrl,
                data,
                headers: headers
            })
        } else if(headers) {
            response = await axios( {
                method: 'post',
                url: targetUrl,
                headers: headers
            })
        } else {
            response = await axios( {
                method: 'post',
                url: targetUrl
            })
        }
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            return {success: true}
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    /**
     * Handles post requests expecting either single item or array results back
     * @param targetUrl
     * @param errorHeader - Header to use for issue log if an error is returned.
     * @param data - Data for request formatted as a string
     * @param headers - Array of headers for request
     */
    public async handlePostRequestReturningNumber(targetUrl: string, errorHeader?: string, data?: string, headers?: AxiosRequestHeaders): Promise<GetGeneralNumberResult> {
        let response: AxiosResponse | ApiDetailResponseProblem;
        if(data && headers) {
            response = await axios({
                method: 'post',
                url: targetUrl,
                data,
                headers: headers
            })
        } else if(headers) {
            response = await axios( {
                method: 'post',
                url: targetUrl,
                headers: headers
            })
        } else {
            response = await axios( {
                method: 'post',
                url: targetUrl
            })
        }
        // Not using instanceOfAxiosResponse typeguard because it doesn't recognize return types from axios interceptor.
        if(!ApiService.instanceOfApiDetailResponseProblem(response)) {
            return {success: true, number: response.data}
        } else {
            return await this.handleErrorResponse(response, errorHeader);
        }
    }

    protected async handleErrorResponse(response: ApiDetailResponseProblem, errorHeader?: string): Promise<ApiDetailResponseProblem> {
        if(errorHeader) {
            const error = this.logService.generateLoggableErrorFromAPIResponse(response, errorHeader);
            await this.logService.logEvent(error);
        } else {
            const error = this.logService.generateLoggableErrorFromAPIResponse(response);
            await this.logService.logEvent(error);
        }
        return {success: false, error: response.error, message: response.message};
    }

    protected async logUnknownError(): Promise<ApiDetailResponseProblem> {
        const returnVal = {success: false, message: {payload: [], success: false, message: {date: new Date(),value: "Unknown",key: "Unknown issue parsing response"}}};
        const error = this.logService.generateLoggableErrorFromAPIResponse(returnVal);
        await this.logService.logEvent(error);
        return returnVal;
    }



}