import SearchFilterBase from "@/components/filters/SearchFilterBase";
import ProposalSearchDTO from "@/dto/ProposalSearchDTO";

export default class ProposalSearchFilter extends SearchFilterBase {

    public designers: Array<string> = [];
    public estimators: Array<string> = [];
    public reasonCodes: Array<number> = [];
    public qaStatuses: Array<string> = [];
    public sampleLocations: Array<string> = [];
    public sampleStatuses: Array<string> = [];
    public customerNames: Array<string> = [];
    public customerPartDesc: Array<string> = [];
    public productNumber!: string;
    public proposalID!: string;
    public requestedDateFrom!: string;
    public requestedDateTo!: string;
    public plantLocation!: string;

    public dateEnteredFrom!: string;
    public dateEnteredTo!: string;
    public designerDateFrom!: string;
    public designerDateTo!: string;
    public designerReleaseDateFrom!: string;
    public designerReleaseDateTo!: string;
    public estimatorDueDateFrom!: string;
    public estimatorDueDateTo!: string;
    public sampleRequestDateFrom!: string;
    public sampleRequestDateTo!: string;

    public attentionRequiredBy!: string;
    public crmCsm!: string;
    public holdCode!: number;
    public subProposalFlag!: number;
    public sampleRequired!: number;

    constructor(init?: Partial<ProposalSearchFilter>) {
        super();
        if (init) Object.assign(this, init);
    }

    get dirty(): boolean {
        for (const key in this) {
            if (this.hasOwnProperty(key)) {
                if (this[key]) {
                    let val = this[key];
                    if (Array.isArray(val)) {
                        if (val.length > 0) {
                            return true;
                        }
                    } else {
                        if (key !== 'recordCount') {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }
}