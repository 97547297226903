import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c366f99a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group search-type-and-or input-standard-size" }
const _hoisted_2 = {
  key: 1,
  class: "col-form-label"
}
const _hoisted_3 = { class: "autocomplete-input-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "chevron__span"
}
const _hoisted_6 = {
  key: 2,
  class: "loading-spinner"
}
const _hoisted_7 = {
  key: 0,
  class: "autocomplete-result-container"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  style: {"margin-left":"15px"},
  class: "nowrap-with-ellipse"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  style: {"margin-left":"15px"},
  class: "nowrap-with-ellipse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_col = _resolveComponent("b-col")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs,
    class: "dropdown-autocomplete"
  }, {
    default: _withCtx(() => [
      _createElementVNode("fieldset", _hoisted_1, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("legend", {
              key: 0,
              class: _normalizeClass(_ctx.isNewUi ? 'col-form-label new-ui-styles' : 'col-form-label')
            }, _toDisplayString(_ctx.label), 3))
          : _createCommentVNode("", true),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("legend", _hoisted_2, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          ref: "autocompleteBaseRef",
          class: "autocomplete-container",
          style: _normalizeStyle({ flexDirection: _ctx.state.direction === 'down' ? 'column' : 'column-reverse' })
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_b_form_input, {
              placeholder: !_ctx.state.hasData && !_ctx.disabled ? _ctx.emptyText : _ctx.placeholder,
              "model-value": _ctx.state.search,
              autocomplete: "off",
              style: {"margin-bottom":"0"},
              disabled: _ctx.inputDisabled,
              autofocus: _ctx.focusOnLoad,
              onInput: _ctx.onChange,
              onKeypress: _withKeys(_ctx.setSelection, ["enter"])
            }, null, 8, ["placeholder", "model-value", "disabled", "autofocus", "onInput", "onKeypress"]),
            (!_ctx.isSet && _ctx.state.results.length > 0)
              ? (_openBlock(), _createBlock(_component_b_button, {
                  key: 0,
                  disabled: _ctx.inputDisabled,
                  class: _normalizeClass(_ctx.isNewUi ? 'button-open-all new-ui-styles' : 'button-open-all'),
                  onClick: _ctx.toggleOpen
                }, {
                  default: _withCtx(() => [
                    (!_ctx.isNewUi)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: _ctx.state.isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'
                          }, null, 8, ["icon"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-up" }),
                          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-down" })
                        ]))
                  ]),
                  _: 1
                }, 8, ["disabled", "class", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isSet && !_ctx.disabled)
              ? (_openBlock(), _createBlock(_component_b_button, {
                  key: 1,
                  class: "button-clear",
                  onClick: _ctx.clear
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clear ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.state.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_b_spinner, { small: "" })
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.state.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.state.results.length > 0 || _ctx.searchBy.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                      (_ctx.searchBy.length > 0 && _ctx.freeForm)
                        ? (_openBlock(), _createBlock(_component_b_button, {
                            key: 0,
                            class: _normalizeClass(['autocomplete-result', { highlight: _ctx.highlight(_ctx.state.search) }]),
                            variant: "light",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setResult(_ctx.state.search)))
                          }, {
                            default: _withCtx(() => [
                              _renderSlot(_ctx.$slots, "option", {
                                item: _ctx.state.search
                              }, () => [
                                (_ctx.highlight(_ctx.state.search))
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                      _createVNode(_component_font_awesome_icon, { icon: "fa-angles-right" })
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.state.search), 1)
                              ], true)
                            ]),
                            _: 3
                          }, 8, ["class"]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.results, (res, index) => {
                        return (_openBlock(), _createBlock(_component_b_button, {
                          key: index,
                          class: _normalizeClass(['autocomplete-result', { highlight: _ctx.highlight(res) }]),
                          variant: "light",
                          onClick: ($event: any) => (_ctx.setResult(res))
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "option", { item: res }, () => [
                              (_ctx.highlight(res))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                    _createVNode(_component_font_awesome_icon, { icon: "fa-angles-right" })
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.getDisplayValue(res)), 1)
                            ], true)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 4)
      ])
    ]),
    _: 3
  }, 8, ["cols", "lg", "md", "sm", "xs"])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}