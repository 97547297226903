import axios from "axios";
import {TOKEN_NAME} from "@/config/properties";
import rootStore from "@/store/RootStore";
import {getDetailApiProblem} from "@/service/api/types/ApiDetailResponseProblem";

export default class ApiConfig {
    private static instance: ApiConfig;

    private constructor() {
        this.initInterceptor()
    }

    static getInstance(): ApiConfig {
        if (!ApiConfig.instance) {
            ApiConfig.instance = new ApiConfig();
        }
        return ApiConfig.instance;
    }

    public setToken(token: string){
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        localStorage.setItem(TOKEN_NAME, token);
    }

    private initInterceptor() {
        axios.interceptors.response.use((response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, (error) => {
            // If error response was returned from request expecting blob type data.
            if(error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        error.response.data = JSON.parse(reader.result as string);
                        this.handleError(error.response.status)
                        const problem = getDetailApiProblem(error.response);
                        resolve(Promise.resolve(problem));
                    }
                    reader.onerror = () => {
                        reject(error);
                    }
                    reader.readAsText(error.response.data);
                });
            }
            // Default error handling
            this.handleError(error.response.status)
            const problem = getDetailApiProblem(error.response);
            if (problem) return problem

        });
    }

    private handleError(status: number) {
        if (status === 401) {
            rootStore.getInstance().profileStore.handleAuthFail()
        }
        if (status === 500) {
            alert('internal server error')
        }
    }
}

