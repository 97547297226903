import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-082190b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "text-input" }
const _hoisted_2 = {
  key: 0,
  class: "col-form-label"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 2,
  class: "sensitive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _directive_popover = _resolveDirective("popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("fieldset", {
      class: "form-group search-type-and-or input-standard-size",
      style: _normalizeStyle({height: _ctx.fillContainer ? '100%' : 'auto' })
    }, [
      (_ctx.radioLabel)
        ? (_openBlock(), _createElementBlock("legend", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.radioLabel) + " ", 1),
            (_ctx.moreInfo)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "fa-solid fa-circle-info",
                  "popover.hover.top": "moreInfo"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.radioLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_b_form_radio_group, {
              options: _ctx.options,
              modelValue: _ctx.and,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.and) = $event)),
              onInput: _ctx.handleRadioChange,
              id: "radioControl"
            }, null, 8, ["options", "modelValue", "onInput"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("legend", {
        class: _normalizeClass({'col-form-label': true, 'highlight':_ctx.hasErrors})
      }, [
        (_ctx.hasErrors)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.label) + " * ", 1),
              (_ctx.moreInfo)
                ? _withDirectives((_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    id: "fieldError",
                    icon: "fa-solid fa-info-circle"
                  }, null, 512)), [
                    [
                      _directive_popover,
                      _ctx.moreInfo,
                      "hover",
                      { hover: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ])), [
              [
                _directive_popover,
                _ctx.errorMessage,
                "hover",
                { hover: true }
              ]
            ])
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.isNewUi ? 'new-ui-styles' : '')
            }, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.moreInfo)
                ? _withDirectives((_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    id: "fieldError",
                    icon: "fa-solid fa-info-circle"
                  }, null, 512)), [
                    [
                      _directive_popover,
                      _ctx.moreInfo,
                      "hover",
                      { hover: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ], 2))
      ], 2),
      (_ctx.sensitive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-eye-slash" })
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            style: _normalizeStyle({height: _ctx.fillContainer ? '100%' : 'auto'})
          }, [
            (_ctx.textArea)
              ? (_openBlock(), _createBlock(_component_b_form_textarea, {
                  key: 0,
                  id: "text-input-form-area",
                  style: _normalizeStyle({fontSize: `${_ctx.fontSize}px`, height: _ctx.fillContainer ? '100%' : 'auto'}),
                  modelValue: _ctx.modelValue,
                  onInput: _ctx.handleChange,
                  onCursorposition: _ctx.emitSelection,
                  disabled: _ctx.disabled,
                  readonly: _ctx.readonly,
                  placeholder: _ctx.placeholder,
                  rows: _ctx.rows,
                  "max-length": _ctx.maxlength,
                  "fill-container": _ctx.fillContainer
                }, null, 8, ["style", "modelValue", "onInput", "onCursorposition", "disabled", "readonly", "placeholder", "rows", "max-length", "fill-container"]))
              : (_openBlock(), _createBlock(_component_b_form_input, {
                  key: 1,
                  id: "text-input-form-input",
                  style: _normalizeStyle({fontSize: `${_ctx.fontSize}px`}),
                  modelValue: _ctx.modelValue,
                  onInput: _ctx.handleChange,
                  onBlur: _ctx.onBlur,
                  disabled: _ctx.disabled,
                  readonly: _ctx.readonly,
                  placeholder: _ctx.placeholder,
                  required: _ctx.needsValue,
                  maxlength: _ctx.maxlength
                }, null, 8, ["style", "modelValue", "onInput", "onBlur", "disabled", "readonly", "placeholder", "required", "maxlength"]))
          ], 4))
    ], 4)
  ]))
}