
import {defineComponent, reactive, watch} from 'vue';
import {useRoute} from "vue-router";
import SideBarNav from "@/views/sidebar/SideBarNav.vue";
import SideBarLog from "@/views/sidebar/SideBarLog.vue";
import sidebarStore from "@/views/sidebar/store/SidebarStore";
import Loading from "@/views/core/components/Loading.vue";
import rootStore from "@/store/RootStore";
import Alert from "@/views/core/components/Alert.vue";
import ReAuth from "@/views/auth/components/ReAuth.vue";
import ApiConfig from "@/service/api/api-config";
import LoadingProgress from "@/views/core/components/LoadingProgress.vue";
import ProfileSummary from "@/views/user/ProfileSummary.vue";
import AuthService from "@/service/AuthService";
import SpoofUserMenu from "@/views/user/SpoofUserMenu.vue";

type State = {
    routesHideSideAndNavBar: Array<string>;
    form_dirty: boolean;
    canSpoofUserGroups: boolean; // If user is Momentum_Admin or UAT_Tester.
    isSpoofingUserGroups: boolean; // If user has user groups to spoof set in their local cache. (spoofing only occurs if their original groups contained Momentum_Admin or UAT_Tester).
    spoofModalOpen: boolean;
}

export default defineComponent({
    components: {SpoofUserMenu, ProfileSummary, SideBarNav, Loading, LoadingProgress, Alert, ReAuth,SideBarLog},
    watch: {
    },

    beforeRouteLeave (to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()){
      next(false)
    } else {
      // Navigate to next view
      next()
    }
    },

    created() {
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    methods: {
      confirmLeave() {
        return window.confirm('Do you really want to leave? You have unsaved changes!')
      },

      confirmStayInDirtyForm() {
        if (this.proposalStore.proposal.proposalNumber) {
          return this.state.form_dirty && !this.confirmLeave()
        }
      },

      beforeWindowUnload(e: any) {
        if (this.confirmStayInDirtyForm()) {
          // Cancel the event
          e.preventDefault()
          // Chrome requires returnValue to be set
          e.returnValue = ''
        }
      },
    },
    setup() {
        window.onload = function() {
          window.addEventListener("beforeunload", function (e) {
            if (!state.form_dirty) {
              return undefined;
            }

            var confirmationMessage = 'It looks like you have been editing something. '
              + 'If you leave before saving, your changes will be lost.';

            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
          });
        };

        const loadingStore = rootStore.getInstance().loadingStore;
        const profileStore = rootStore.getInstance().profileStore;
        const proposalStore = rootStore.getInstance().proposalStore;
        const authService = new AuthService();

        const spoofingActiveStr = localStorage.getItem("groupSpoofingActive");

        const state = reactive<State>({
            routesHideSideAndNavBar: ['LoginScreen'],
            form_dirty: rootStore.getInstance().proposalStore.isDirty,
            canSpoofUserGroups: false,
            isSpoofingUserGroups: spoofingActiveStr? JSON.parse(spoofingActiveStr): false,
            spoofModalOpen: false
        })

        watch(() => profileStore.groups, (groupsValue)=> {
          if(groupsValue.filter((g) => {return g.hasSpoofingPermission();}).length > 0) {
            state.canSpoofUserGroups = profileStore.allowsSpoofing;
          } else {
            state.canSpoofUserGroups = false;
          }
        })

        const proposalRoutes = rootStore.getInstance().proposalStore.routes;
        const route = useRoute();
        const routeParams = route.params;

        watch(() => [rootStore.getInstance().proposalStore.isDirty], () => {
          state.form_dirty = rootStore.getInstance().proposalStore.isDirty
        });

        const proposalNumber = rootStore.getInstance().proposalStore.proposal;

        var isNewRoute: boolean = route.path.includes('-new/');
        console.log(isNewRoute);
        watch(() => route.path, (newPath, oldPath) => {
          isNewRoute = route.path.includes('-new/');
          console.log(isNewRoute);
        });

        function toggleNav() {
            sidebarStore.getInstance().toggleSidebar();
        }

        function logout() {
            //AuthStore.getInstance().signOut('logout')
            authService.logout()
        }

        function endSpoof() {
          localStorage.removeItem("groupSpoofingActive");
          localStorage.removeItem("spoofedUserGroups");
          window.location.reload();
        }
//depreciated
        function expireToken() {
            ApiConfig.getInstance().setToken('broke')
            ApiConfig.getInstance().setToken('broke')
        }

        return {
            state,
            toggleNav,
            logout,
            loadingStore,
            profileStore,
            proposalStore,
            expireToken,
            endSpoof,
            proposalRoutes,
            routeParams,
            isNewRoute
        }
    }
})
