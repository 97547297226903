import NotificationApiService from "@/service/api/NotificationApiService";
import EmailRequestDTO from "@/dto/EmailRequestDTO";
import UserTag from "@/dto/UserTag";
import rootStore from "@/store/RootStore";
import {GetGeneralListSuccessResult, GetGeneralSuccessResult} from "@/service/api/types/ApiResponseTypes";
import axios, {AxiosResponse} from "axios";
import {BASE_API_URL} from "@/config/env";
import ProposalPriceListEntry from "@/domain/proposal/ProposalPriceListEntry";

export default class NotificationService {

    private notificationApiService;
    private notificationStore = rootStore.getInstance().notificationStore;
    private maintenanceStore = rootStore.getInstance().maintenanceStore;

    constructor() {
        this.notificationApiService = new NotificationApiService();
    }

    public async getTaggableUsers(): Promise<boolean> {
        const response = await this.notificationApiService.getTaggableUsers();
        if (response.success && response.items) {
            this.notificationStore.taggableUsers = response.items;
        }
        return response.success;
    }

    public async getTaggableUsersByGroup(groupId: number): Promise<Array<UserTag>> {
        const response = await this.notificationApiService.getTaggableUsersByGroup(groupId);
        if (response.success && response.items) {
            return response.items;
        }
        return []
    }

    public getUsersFromTag(tag: string): UserTag[] {
        return this.notificationStore.taggableUsers.filter(t => t.fullName.toUpperCase().includes(tag.toUpperCase()));
    }

    public getUserTagByName(value: string): UserTag | undefined {
        const user = this.maintenanceStore.activeUsers.find(u => u.username === value || u.salesForceName === value);
        if (user) {
            return this.notificationStore.taggableUsers.find(t => t.userId === user.userID);
        }
        return undefined;
    }

    public async sendNotification(emailRequestDTO: EmailRequestDTO): Promise<boolean> {
        const response = await this.notificationApiService.sendNotification(emailRequestDTO);
        if(response.success) {
            //nothing, email sent successfully
        }
        return response.success;
    }

    public async getUserFromTag(fullName: string) {
         const response = await this.notificationApiService.getUserTags(fullName);
         if (response.success && response.items) {
             return response.items[0];
         }
         return '';
    }

    public async sendVoidedEmail(emailRequestDTO: EmailRequestDTO): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(emailRequestDTO);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/email/notifyVoidedProposal`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return { success: false }

        try {
            return { success: true }
        } catch {
            return { success: false }
        }
    }

    public async getPriceList(productid: number): Promise<GetGeneralListSuccessResult<ProposalPriceListEntry>> {
        const data = JSON.stringify(productid);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/proposal/getPriceList`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.data) return { success: false, items: [] };

        try {
            return { success: true, items: response.data.payload };
        } catch {
            return { success: false, items: [] };
        }
    }
}