
    import {defineComponent, onMounted, watchEffect} from "vue";
    import {useRoute} from "vue-router";
    import sidebarStore from './store/SidebarStore'
    import {computed, onBeforeMount, onBeforeUnmount, reactive, watch} from "vue";
    import sideBarNavItemManager from "./SideBarNavItemManager";
    import SideBarMenuItem from "./domain/SideBarMenuItem";
    import router from '@/router';
    import SidebarNavProposal from "@/views/sidebar/SideBarNavProposal.vue";
    import rootStore from "@/store/RootStore";
    import ProposalPageLeftSideNav from "../proposal/proposalLeftSideNavComponents/ProposalPageLeftSideNav.vue";

    type State = {
        screenWidth: number;
        accordion: Array<number>;
        loading: boolean;
    }

    export default defineComponent({
    components: {SidebarNavProposal, ProposalPageLeftSideNav},
      props: {
            hide: {
                type: Boolean,
                required: false,
            }
        },
      setup: function (props, context) {
        const route = useRoute();
        const sidebar = sidebarStore.getInstance();

        const sidebarManager = reactive(sideBarNavItemManager);
        //to grey-out text indicating not clickable.
        const profileGroups = rootStore.getInstance().profileStore.groups;

        const state = reactive<State>({
          screenWidth: 0,
          accordion: [],
          loading: true
        })

        watch(() => [props.hide], (val) => {
          if (val) sidebar.sideBarVisible = false;
        });

        watch(() => [sidebar.sideBarVisible], () => {
          state.accordion = [];
        });

        watchEffect(() => {
          const val = router.currentRoute.value;
          if (val.params && val.params['proposalNumber']) {
            sidebarManager.showProposalNav();
            sidebar.sideBarVisible = true;
          } else {
            sidebarManager.hideProposalNav();
          }
        })

        const mobile = computed((): boolean => {
          return state.screenWidth <= sidebar.mobileDesktopBreak
        })

        const keepOpen = computed((): boolean => {
          return state.screenWidth > sidebar.keepOpenWidth
        })

        const sideBarWidth = computed((): number => {
          if (!mobile.value) {
            return route.path.includes('-new/') ? 210 : sidebar.sideBarWidthDesktop;
          }
          return sidebar.sideBarWidthMobile;
        })

        const sideBarOpenCss = computed(() => {
          return {
            width: sideBarWidth.value + 'px',
            left: 0,
          };
        })

        const sideBarCloseCss = computed(() => {
          return {
            left: -sideBarWidth.value + 'px',
            transition: 'all 0.3s',
          };
        })

        const containerOpenCss = computed(() => {
          if (!mobile.value) {
            return {
              width: 'calc(100% - ' + sideBarWidth.value + 'px) !important',
            };
          }
          return {};
        })

        function handleResize() {
          state.screenWidth = window.innerWidth;
          if (keepOpen.value && !props.hide) {
            if (!sidebar.sideBarVisible) {
              sidebar.toggleSidebar();
            }
          } else {
            if (sidebar.sideBarVisible && !state.loading) {
              sidebar.toggleSidebar();
            }
          }
        }

        function toggleAccordion(value: number): void {
          if (state.accordion.includes(value)) {
            const index = state.accordion.indexOf(value);
            if (index !== -1) {
              state.accordion.splice(index, 1);
            }
          } else {
            state.accordion.push(value);
          }
        }

        function hideSideBar(): void {
          if (sidebar.sideBarVisible) {
            sidebar.toggleSidebar();
          }
        }

        function navClick(route: string): void {
          router.push({name: route});
          // Messily added condition to keep open when in proposal screen. Should reconsider a cleaner approach to when sidebar should automatically close and open
          if (mobile.value || !keepOpen.value && !router.currentRoute.value.params) {
            hideSideBar();
          }
        }

        function hasAccess(item: SideBarMenuItem) {
          if (item.routeName === 'WorkflowScreen') {
            return profileGroups.find(grp => grp.userGroupName.toLowerCase().includes('workflow administrator'));
          }
          if (item.routeName === 'DesignQueue') {
            return profileGroups.find(grp =>
                grp.userGroupName.toLowerCase().includes('momentumadmin') ||
                grp.userGroupName.toLowerCase().includes('design lead'));
          }
          if (item.routeName === 'DesignerQueue') {
            return profileGroups.find(grp =>
                grp.userGroupName.toLowerCase().includes('designers'));
          }
          if (item.routeName === 'DesignerSkills') {
            return profileGroups.find(grp => grp.userGroupName.toLowerCase().includes('momentumadmin') ||
                grp.userGroupName.toLowerCase().includes('design lead'));
          }
          if (item.routeName === 'CustomerProducts') {
            return profileGroups.find(grp => grp.userGroupName.toLowerCase().includes('momentumadmin') ||
                grp.userGroupName.toLowerCase().includes('design lead'));
          }

          if (item.routeName == 'UserLocations') {
            return profileGroups.find(grp =>
                grp.userGroupName.toLowerCase().includes('momentumadmin'));
          }
          return false;
        }

        onBeforeMount(() => {
          window.addEventListener('resize', handleResize);
          handleResize();
        })

        onMounted(() => {
          let showSidebar = sessionStorage.getItem('sidebar.sideBarVisible');
          showSidebar === 'true' ? sidebar.sideBarVisible = true : sidebar.sideBarVisible = false;
          if (showSidebar) {
            if (sidebar.sideBarVisible) {
              if (!props.hide) {
                //we're good
              } else {
                sidebar.toggleSidebar();
              }
            }
          }
          state.loading = false;
        })

        onBeforeUnmount(() => {
          window.removeEventListener('resize', handleResize);
        })

        return {
          sidebar,
          sidebarManager,
          state,
          mobile,
          keepOpen,
          sideBarOpenCss,
          sideBarCloseCss,
          sideBarWidth,
          containerOpenCss,
          toggleAccordion,
          hideSideBar,
          navClick,
          SideBarMenuItem,
          hasAccess
        }
      }
    })

