
import AdvancedSearch from "@/views/search/AdvancedSearchScreen.vue";
import {defineComponent} from "vue";
import Proposal from "@/domain/proposal/Proposal";
import router from "@/router";

export default defineComponent({
  name: "SearchController",
  components: {AdvancedSearch},
  setup(props, context) {

    function openProposal(proposal: Proposal) {
      const routeData = router.resolve({
        name: 'Proposal', params: {proposalNumber: proposal.proposalNumber}
      });
      window.open(routeData.href, '_blank');
    }

    return {
      openProposal
    }
  }
})
