import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import User from "@/domain/profile/User";
import Group from "@/domain/profile/Group";
import {ref} from "vue";

@store
export default class UserGroupStore {

    public users = ref<User[]>([]);
    public groups = ref<Group[]>([]);

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
}