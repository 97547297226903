import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788736a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proposal__field" }
const _hoisted_2 = { class: "proposal__label" }
const _hoisted_3 = { class: "proposal__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.item.label), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.value), 1)
  ]))
}