
import { defineComponent } from 'vue';
import ProposalFieldValue from './ProposalFieldValue.vue';
import TeamSectionItem from './TeamSectionItem.vue';
import FormMultiSelect from '@/components/FormMultiselect.vue';
import DropdownAutocompleteSingleSelect from '@/components/DropdownAutocompleteSingleSelect.vue';

export default defineComponent({
    name: 'proposal-page-left-side-nav',
    components:{
        ProposalFieldValue, TeamSectionItem, FormMultiSelect, DropdownAutocompleteSingleSelect
    },
    data() {
        return {
            dummyProposalFieldData: [
              { label: 'Proposal', value: '854670'},
              { label: 'Status', value: 'In Design'},
              { label: 'Product', value: 'D1067228R01'},
              { label: 'Reason Code', value: '100 | New - Print / Quote / sample'},
              { label: 'Opportunity', value: 'O - 000280396'},
              { label: 'Request Date', value: '07/16/2024'},
              { label: 'Valid From', value: '07/16/2024'},
              { label: 'Valid To', value: '07/16/2024'},
              { label: 'Plant', value: '4005 | Northwood Sal'},
              { label: 'Description', value: 'Nemo enim ipsam volu'},
            ],
            dummyTeamMembers: [
              { role: 'Designer', name: 'John Doe', imageURL: "dummyURL"},
              { role: 'Estimator', name: 'Jane Doe', imageURL: "dummyURL"},
              { role: 'Sample Maker', name: 'John Smith', imageURL: "dummyURL"},
              { role: 'Approver', name: 'Jane Smith', imageURL: "dummyURL"},
            ],
            selectOptions: [
              { value: 'volvo', label: 'Volvo'},
              { value: 'saab', label: 'Saab'},
              { value: 'mercedes', label: 'Mercedes'},
              { value: 'audi', label: 'Audi'},
            ],
            selectedOptions: [],
            selectedSingleOption: ''
        };
    },
    props: {
        navBarStyle: {
                type: Object,
                required: true,
            }
        },
    setup(props, ctx) {
        const {navBarStyle} = props;
        return {
            navBarStyle
        };
    },
});

